.close:hover {
  cursor: pointer;
}

.error-notification-wrapper {
  top: 20px;
}

.error-notification-wrapper div {
  width: 60%;
}

.error-notification-wrapper ul {
  width: 95%;
  /* list-style-type: '- '; */
}