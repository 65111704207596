table {
  margin: 0px !important;
}

th {
  font-weight: 700 !important;
}

td,
th {
  text-align: left !important;
  color: #000 !important;
}

th.actions-head {
  min-width: 154px;
}