.properties-total-stats-wrapper {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 16px;
}

.properties-total-stats-wrapper .total-value h6:first-child {
  color: #31ce36;
}

.properties-total-stats-wrapper .bar-chart-wrapper {
  width: 100%;
  height: 200px;
}