.theme-purple ::selection {
  color: #fff;
  background: #a27ce6;
}
.theme-purple .page-loader-wrapper {
  background: #a27ce6;
}
.theme-purple #left-sidebar .nav-tabs .nav-link.active {
  color: #a27ce6;
}
.theme-purple:before,
.theme-purple:after {
  background: #a27ce6;
}
.theme-purple #wrapper:before,
.theme-purple #wrapper:after {
  background: #a27ce6;
}
.theme-purple .sidebar-nav .metismenu > li.active > a {
  border-left-color: #a27ce6;
}
.theme-purple .navbar-nav ul.menu-icon {
  background: #a27ce6;
}
.theme-purple .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #a27ce6;
  border-color: #a27ce6;
}
.theme-purple .chat-widget li.right .chat-info {
  background: #a27ce6;
  color: #fff;
}
.theme-purple .chat-widget li.right .chat-info:before {
  border-left: 10px solid #a27ce6;
}
.theme-purple
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #a27ce6;
  border-color: #a27ce6;
  color: #fff;
}
.theme-purple .blog-page .single_post .footer .stats li a:hover {
  color: #a27ce6;
}
.theme-purple .auth-main .btn-primary {
  background: #a27ce6;
  border-color: #a27ce6;
}
.theme-purple .sidebar-nav .metismenu > li i {
  color: #a27ce6;
}
.theme-purple .right_chat li a:hover .media-object {
  border-color: #a27ce6;
}
.theme-blue ::selection {
  color: #fff;
  background: #3eacff;
}
.theme-blue .page-loader-wrapper {
  background: #3eacff;
}
.theme-blue #left-sidebar .nav-tabs .nav-link.active {
  color: #3eacff;
}
.theme-blue:before,
.theme-blue:after {
  background: #3eacff;
}
.theme-blue #wrapper:before,
.theme-blue #wrapper:after {
  background: #3eacff;
}
.theme-blue .sidebar-nav .metismenu > li.active > a {
  border-left-color: #3eacff;
}
.theme-blue .navbar-nav ul.menu-icon {
  background: #3eacff;
}
.theme-blue .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #3eacff;
  border-color: #3eacff;
}
.theme-blue .chat-widget li.right .chat-info {
  background: #3eacff;
  color: #fff;
}
.theme-blue .chat-widget li.right .chat-info:before {
  border-left: 10px solid #3eacff;
}
.theme-blue
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #3eacff;
  border-color: #3eacff;
  color: #fff;
}
.theme-blue .blog-page .single_post .footer .stats li a:hover {
  color: #3eacff;
}
.theme-blue .auth-main .btn-primary {
  background: #3eacff;
  border-color: #3eacff;
}
.theme-blue .sidebar-nav .metismenu > li i {
  color: #3eacff;
}
.theme-blue .right_chat li a:hover .media-object {
  border-color: #3eacff;
}
.theme-cyan ::selection {
  color: #fff;
  background: #49c5b6;
}
.theme-cyan .page-loader-wrapper {
  background: #49c5b6;
}
.theme-cyan #left-sidebar .nav-tabs .nav-link.active {
  color: #49c5b6;
}
.theme-cyan:before,
.theme-cyan:after {
  background: #49c5b6;
}
.theme-cyan #wrapper:before,
.theme-cyan #wrapper:after {
  background: #49c5b6;
}
.theme-cyan .sidebar-nav .metismenu > li.active > a {
  border-left-color: #49c5b6;
}
.theme-cyan .navbar-nav ul.menu-icon {
  background: #49c5b6;
}
.theme-cyan .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #49c5b6;
  border-color: #49c5b6;
}
.theme-cyan .chat-widget li.right .chat-info {
  background: #49c5b6;
  color: #fff;
}
.theme-cyan .chat-widget li.right .chat-info:before {
  border-left: 10px solid #49c5b6;
}
.theme-cyan
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #49c5b6;
  border-color: #49c5b6;
  color: #fff;
}
.theme-cyan .blog-page .single_post .footer .stats li a:hover {
  color: #49c5b6;
}
.theme-cyan .auth-main .btn-primary {
  background: #49c5b6;
  border-color: #49c5b6;
}
.theme-cyan .sidebar-nav .metismenu > li i {
  color: #49c5b6;
}
.theme-cyan .right_chat li a:hover .media-object {
  border-color: #49c5b6;
}
.theme-green ::selection {
  color: #fff;
  background: #50d38a;
}
.theme-green .page-loader-wrapper {
  background: #50d38a;
}
.theme-green #left-sidebar .nav-tabs .nav-link.active {
  color: #50d38a;
}
.theme-green:before,
.theme-green:after {
  background: #50d38a;
}
.theme-green #wrapper:before,
.theme-green #wrapper:after {
  background: #50d38a;
}
.theme-green .sidebar-nav .metismenu > li.active > a {
  border-left-color: #50d38a;
}
.theme-green .navbar-nav ul.menu-icon {
  background: #50d38a;
}
.theme-green .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #50d38a;
  border-color: #50d38a;
}
.theme-green .chat-widget li.right .chat-info {
  background: #50d38a;
  color: #fff;
}
.theme-green .chat-widget li.right .chat-info:before {
  border-left: 10px solid #50d38a;
}
.theme-green
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #50d38a;
  border-color: #50d38a;
  color: #fff;
}
.theme-green .blog-page .single_post .footer .stats li a:hover {
  color: #50d38a;
}
.theme-green .auth-main .btn-primary {
  background: #50d38a;
  border-color: #50d38a;
}
.theme-green .sidebar-nav .metismenu > li i {
  color: #50d38a;
}
.theme-green .right_chat li a:hover .media-object {
  border-color: #50d38a;
}
.theme-orange ::selection {
  color: #fff;
  background: #ffce4b;
}
.theme-orange .page-loader-wrapper {
  background: #ffce4b;
}
.theme-orange #left-sidebar .nav-tabs .nav-link.active {
  color: #ffce4b;
}
.theme-orange:before,
.theme-orange:after {
  background: #ffce4b;
}
.theme-orange #wrapper:before,
.theme-orange #wrapper:after {
  background: #ffce4b;
}
.theme-orange .sidebar-nav .metismenu > li.active > a {
  border-left-color: #ffce4b;
}
.theme-orange .navbar-nav ul.menu-icon {
  background: #ffce4b;
}
.theme-orange .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #ffce4b;
  border-color: #ffce4b;
}
.theme-orange .chat-widget li.right .chat-info {
  background: #ffce4b;
  color: #fff;
}
.theme-orange .chat-widget li.right .chat-info:before {
  border-left: 10px solid #ffce4b;
}
.theme-orange
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #ffce4b;
  border-color: #ffce4b;
  color: #fff;
}
.theme-orange .blog-page .single_post .footer .stats li a:hover {
  color: #ffce4b;
}
.theme-orange .auth-main .btn-primary {
  background: #ffce4b;
  border-color: #ffce4b;
}
.theme-orange .sidebar-nav .metismenu > li i {
  color: #ffce4b;
}
.theme-orange .right_chat li a:hover .media-object {
  border-color: #ffce4b;
}
.theme-blush ::selection {
  color: #fff;
  background: #e47297;
}
.theme-blush .page-loader-wrapper {
  background: #e47297;
}
.theme-blush #left-sidebar .nav-tabs .nav-link.active {
  color: #e47297;
}
.theme-blush:before,
.theme-blush:after {
  background: #e47297;
}
.theme-blush #wrapper:before,
.theme-blush #wrapper:after {
  background: #e47297;
}
.theme-blush .sidebar-nav .metismenu > li.active > a {
  border-left-color: #e47297;
}
.theme-blush .navbar-nav ul.menu-icon {
  background: #e47297;
}
.theme-blush .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #e47297;
  border-color: #e47297;
}
.theme-blush .chat-widget li.right .chat-info {
  background: #e47297;
  color: #fff;
}
.theme-blush .chat-widget li.right .chat-info:before {
  border-left: 10px solid #e47297;
}
.theme-blush
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #e47297;
  border-color: #e47297;
  color: #fff;
}
.theme-blush .blog-page .single_post .footer .stats li a:hover {
  color: #e47297;
}
.theme-blush .auth-main .btn-primary {
  background: #e47297;
  border-color: #e47297;
}
.theme-blush .sidebar-nav .metismenu > li i {
  color: #e47297;
}
.theme-blush .right_chat li a:hover .media-object {
  border-color: #e47297;
}
