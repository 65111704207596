#finance-plus-widget-affordability-eligibility {
  padding: 2rem 0;
  background-color: #ffffff;
}
/* #finance-plus-widget-affordability-eligibility .full {
  height: 100vh;
} */

.finance-plus-widget-affordability-eligibility-wrapper {
  padding: 6rem 0;
}

.finance-plus-widget-affordability-eligibility-wrapper h2 span {
  color: #121212;
  display: block;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
}

.finance-plus-widget-form {
  padding: 1rem;
}

.finance-plus-widget-form label {
  /* font-weight: 800; */
  color: rgb(64, 64, 64, 0.8);
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.7rem;
}

.finance-plus-widget-form .form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #f1f1f1;
  color: #00b1ab;
  top: 10px;
  height: 30px;
  width: 30px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 7px;
  outline: none;
}

.finance-plus-widget-form .form-radio:checked::before {
  position: absolute;
  font: 13px;
  left: 11px;
  top: 4px;
  content: "\02143";
  transform: rotate(40deg);
}

.finance-plus-widget-form .form-radio:hover {
  background-color: #f7f7f7;
}

.finance-plus-widget-form .form-radio:checked {
  background-color: #f1f1f1;
}

.finance-plus-widget-form .form-control {
  color: #1c273c;
  font-weight: 500;
  border-width: 1px;
  border-color: #cdd4e0;
  height: 45px;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-clip: padding-box;
}

.finance-plus-widget-form input:focus {
  box-shadow: none;
}

.finance-plus-widget-form input::-ms-value,
.finance-plus-widget-form input::-webkit-value {
  font-weight: 700;
  color: #97a3b9;
  opacity: 1;
  font-size: 0.9rem;
  float: right;
}

.finance-plus-widget-form .irs--round .irs-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border: 4px solid #00b1ab;
}

.finance-plus-widget-form .irs--round .irs-to {
  background-color: #00b1ab;
}

.finance-plus-widget-form .irs--round .irs-from::before {
  border-top-color: #00b1ab;
}

.finance-plus-widget-form .irs--round .irs-bar {
  top: 36px;
  height: 4px;
  background-color: #00b1ab;
}

.finance-plus-widget-form .irs--round .irs-single {
  background-color: #00b1ab;
}

.irs--round .irs-from::before,
.irs--round .irs-to::before,
.irs--round .irs-single::before {
  border-top-color: #00b1ab !important;
}

.finance-plus-widget-affordability-eligibility-form {
  background: #ffffff;
  padding: 2rem 1rem;
  margin-top: 3rem;
}

.finance-plus-widget-affordability-eligibility-form-summary {
  border: 2px solid #f6f8f9;
  padding: 1rem 2rem;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
}

.finance-plus-widget-affordability-eligibility-summary-content-header {
  padding: 0 2.5rem;
  background: #00b1ab;
  border-radius: 5px;
  margin-top: 1rem;
}

.finance-plus-widget-affordability-eligibility-summary-content-header-text {
  display: flex;
  justify-content: flex-start;
  padding: 2rem 0;
}

.finance-plus-widget-affordability-eligibility-summary-content-header-text i {
  font-size: 40px;
  color: #ffffff;
}

.finance-plus-widget-affordability-eligibility-summary-content-header-text p {
  font-size: 25px;
  color: #ffffff;
  /* margin-left: 10px; */
  line-height: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.finance-plus-widget-affordability-eligibility-summary-content-bottom-text {
  background: #ffffff;
  margin-top: -40px;
  box-shadow: 0 1px 46px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 1rem 0;
}
.funds-summary-limit {
  padding: 0 1rem;
}

.funds-summary-limit p {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
}

.funds-summary-limit h2 {
  text-align: center;
  font-size: 1.9rem;
  font-weight: 800;
  color: #404040;
  margin-top: -10px;
  letter-spacing: 1.2;
  font-family: "DM Sans", sans-serif;
}

.funds-summary-limit-divider {
  /* width: 100%;
  height: 1px;
  border-top: 1px solid rgb(204, 203, 203, 0.5);
  margin: 3px 0 20px; */
  width: 50%;
  height: 1px;
  border-top: 1px solid rgb(204, 203, 203, 0.5);
  margin: 5px 70px 20px;
  text-align: center;
}

.funds-summary-limit h4 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 1.2;
  color: #8b8b8b;
  font-family: "DM Sans", sans-serif;
}

.funds-summary-limit .funds-summary-rate {
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(64, 64, 64, 0.5);
  margin-top: -5px;
  font-family: "DM Sans", sans-serif;
}

.property-funding-wrapper {
  justify-content: center;
  text-align: center;
}

/* .property-funding-wrapper p {
  text-align: center;
  font-weight: 800;
  color: #ffffff;
  margin-top: 10px;
} */

.property-funding-wrapper .view-matching-properties {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 15px 20px !important;
  margin: 0 auto;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: -5px;
  text-decoration: none !important;
}

.property-funding-wrapper .view-matching-properties:hover {
  background-color: #000000;
  border: 1px solid #000000;
}

.poweredBy {
  text-align: center;
  float: center;
}

.poweredBy p {
  margin-top: -40px;
}
.poweredBy img {
  height: 100px;
  margin-left: -5px;
}

/* Price Slider */
.range-slider {
  margin: 10px 0 0 0%;
}

.range-slider {
  width: 100%;
}

.range-slider__range {
  -webkit-appearance: none;
  /* height: 8px; */
  /* border-radius: 5px; */
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
  height: 6px;
  width: 100%;
  background: #d1deec;
  border-radius: 6px;
}
.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  width: 40px;
  height: 25px;
  border: 0;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  border: 4px solid #00b1ab;
  box-shadow: rgba(97, 115, 136, 0.2) 0px 1px 4px,
    rgba(57, 71, 87, 0.16) 0px 2px 20px, rgb(255, 106, 255) 0px 0px 0px,
    rgb(62, 232, 255) 0px 0px 0px, rgb(255, 245, 0) 0px 0px 0px;
}
.range-slider__range::-webkit-slider-thumb:hover {
  background: #1abc9c;
}
.range-slider__range:active::-webkit-slider-thumb {
  background: #1abc9c;
}
.range-slider__range::-moz-range-thumb {
  /* width: 20px;
  height: 20px; */
  width: 35px;
  height: 15px;
  border: 0;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  border: 4px solid #00b1ab;
  box-shadow: rgba(97, 115, 136, 0.2) 0px 1px 4px,
    rgba(57, 71, 87, 0.16) 0px 2px 20px, rgb(255, 106, 255) 0px 0px 0px,
    rgb(62, 232, 255) 0px 0px 0px, rgb(255, 245, 0) 0px 0px 0px;
}
.range-slider__range::-moz-range-thumb:hover {
  background: #1abc9c;
}
.range-slider__range:active::-moz-range-thumb {
  background: #1abc9c;
}
.range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #1abc9c;
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #2c3e50;
  padding: 5px 10px;
  margin-left: 8px;
}
.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #2c3e50;
  border-bottom: 7px solid transparent;
  content: "";
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

/* Property Suggestion */
.finance-widget-property-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f6f8f9;
}

.finance-widget-property-wrapper-list {
  overflow: scroll;
  width: 100%;
  margin-top: 44px;
  margin-bottom: 44px;
  padding-left: 10%;
  justify-content: flex-start;
  display: flex;
}

.finance-widget-property-list {
  margin-right: 0.7rem;
}

.finance-widget-property-wrapper-list .property-card {
  max-width: 300px;
  margin-right: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
  border-radius: 10px;
  background-color: #fff;
  cursor: default;
}

.finance-widget-property-wrapper-list .property-image-holder {
  height: 200px;
  border-radius: 10px 10px 0px 0px;
  background-image: url("https://app.newhomes.ng/storage/properties/NedcomoaksLimited.Alexdr2.jpeg");
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.finance-widget-property-wrapper-list .property-card-price-holder {
  display: flex;
  margin-top: 9px;
  margin-bottom: 0px;
  padding-right: 16px;
  padding-left: 16px;
  justify-content: space-between;
  align-items: center;
}

.finance-widget-property-wrapper-list .property-card-property-status {
  margin-right: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #00b1ab;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  font-size: 10px;
}
.finance-widget-property-wrapper-list .property-card-price {
  font-family: "DM Sans", sans-serif;
  color: #00b1ab;
  font-weight: 700;
  font-size: 1rem;
}

.finance-widget-property-wrapper-list .property-card-name-holder {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
}

.finance-widget-property-wrapper-list .property-card-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-size: 17px;
  font-weight: 500;
}

.finance-widget-property-wrapper-list .property-card-extras {
  display: flex;
  color: #adadad;
}

.property-card-location {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.finance-widget-property-wrapper-list .property-card-bed-bath-holder {
  display: flex;
  padding: 11px 16px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e8e9ea;
}

.finance-widget-property-wrapper-list .property-card-inner-side {
  display: flex;
}

.finance-widget-property-wrapper-list .property-card-icon {
  margin-right: 9px;
  font-family: "Fa 400", sans-serif;
  color: #b1b1b1;
}

.finance-widget-property-wrapper-list .property-card-bed-bath {
  border-left: 1px none #c4c4c4;
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.finance-widget-property-wrapper-list .property-card-inner-divider {
  width: 1px;
  height: 20px;
  background-color: #e8e9ea;
}

.finance-widget-property-wrapper-list .property-mortgage-deets-holder {
  display: flex;
  margin-top: 0px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e8e9ea;
}

.finance-widget-property-wrapper-list .property-card-inner-block {
  display: flex;
  flex-direction: column;
}

.finance-widget-property-wrapper-list .property-mortgage-heading {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  color: #adadad;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.finance-widget-property-wrapper-list .property-mortgage-wrapper {
  display: flex;
  align-items: center;
}

.finance-widget-property-wrapper-list .property-mortage-value {
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

.finance-widget-property-wrapper-list .property-mortgage-freq {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.finance-widget-property-wrapper-list .property-card-inner-divider.long {
  height: 40px;
}
.finance-widget-property-wrapper-list .property-card-inner-divider {
  width: 1px;
  height: 20px;
  background-color: #e8e9ea;
}

.finance-widget-property-wrapper-list .property-repayment-heading {
  margin-bottom: 0px;
  align-self: flex-end;
  font-family: "DM Sans", sans-serif;
  color: #adadad;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.finance-widget-property-wrapper-list .property-repayment-wrapper {
  display: flex;
  align-items: center;
  align-self: auto;
}

.finance-widget-property-wrapper-list .property-repayment-period {
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

.finance-widget-property-wrapper-list .property-repayment-freq {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.finance-widget-property-wrapper-list .property-project-card-cta {
  width: 100%;
  padding: 11px 30px;
  border-top: 1px solid #e8e9ea !important;
  border-radius: 0px 0px 10px 10px;
  background-color: #fff;
  font-family: "Droid Sans", sans-serif;
  color: #00b1ab;
  font-size: 12px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.finance-widget-property-wrapper-list .property-project-card-cta:hover {
  background-color: #00b1ab;
  color: #fff;
}

.finance-widget-property-wrapper-list .o-button {
  display: inline-block;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

.finance-plus-widget-affordability-eligibility-form-summary.single-page {
  left: 100%;
  z-index: 101;
  width: 75%;
  position: absolute;
  border: none;
  top: 0;
  padding: 0;
}

.finance-plus-widget-affordability-eligibility-summary-content {
  position: sticky;
  bottom: 0px;
  margin-top: 40px;
  top: 0;
  z-index: 1001;
}

/* price summary form */
.price-summary-form label {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "Droid Sans", sans-serif;
  letter-spacing: 2px;
}

.price-summary-form input {
  height: 40px;
  width: 100%;
}
.price-summary-form input:focus {
  box-shadow: none !important;
  border: 1px solid #136b3d;
}

.price-summary-form input::-ms-value {
  text-align: left;
  font-size: 1.9rem;
  font-weight: 800;
  color: #404040;
  margin-top: -10px;
  letter-spacing: 1.2;
  font-family: "DM Sans", sans-serif;
}

.summary-property-price-box {
  /* margin-top: 36px; */
  padding: 20px;
  border-radius: 5px;
  /* border-top: 1px solid #e8e9ea; */
}

/* .price-box-inspection-wrapper {
  display: none;
} */

.col-md-12.affordability-eligibility-mobile-summary {
  display: none;
}

.mobile-affordability-eligibility-summary,
.finance-plus-widget-form .mobile-view-matching-properties {
  display: none;
}

/* Responsive Queries */
/* @media screen and (max-width: 991px) {
  #finance-plus-widget-affordability-eligibility .container {
    max-width: 100%;
  }

  .finance-plus-widget-affordability-eligibility-wrapper h2 span {
    padding: 0rem 2rem;
  }

  .col-md-12.mobile-affordability-eligibility-form-summary {
    position: fixed;
    top: 0;
    max-width: 100%;
    background: #00b1ab;
    padding: 0;
    z-index: 10;
  }

  .finance-plus-widget-affordability-eligibility-form {
    padding: 0;
  }

  .mobile-affordability-eligibility-summary {
    padding: 0.7rem 2.5rem 0.3rem;
    display: flex;
    justify-content: space-between;
  }

  .mobile-loanable-amount span,
  .mobile-monthly-repayment span {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .mobile-loanable-amount h2,
  .mobile-monthly-repayment h2 {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
  }

  .mobile-summary-divider {
    height: 60px;
    width: 1px;
    background-color: #e8e9ea;
  }

  .col-md-6.affordability-eligibility-form {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1rem 2rem;
  }

  .col-md-6.affordability-eligibility-form-summary {
    display: none;
  }

  .finance-plus-widget-form .mobile-view-matching-properties {
    background-color: #00b1ab;
    border: 1px solid #00b1ab;
    padding: 15px 20px !important;
    margin: 0 auto;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.9rem;
    margin-top: -5px;
    text-decoration: none !important;
    text-align: center;
  }

  .finance-plus-widget-form .mobile-view-matching-properties:hover {
    background-color: #000000;
    border: 1px solid #000000;
  }

 
}

@media screen and (max-width: 767px) {
}
@media screen and (max-width: 479px) {
  .mobile-affordability-eligibility-summary,
  .finance-plus-widget-form .mobile-view-matching-properties {
    display: none;
  }
  .finance-plus-widget-affordability-eligibility-form {
    margin-top: 1rem;
  }

  .mobile-affordability-eligibility-summary {
    padding: 1rem 0.5rem 0.3rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .mobile-summary-divider {
    height: 1px;
    width: 100%;
    background-color: rgb(232, 233, 234, 0.5);
    margin: 0.7rem 0;
  }

  .finance-plus-widget-affordability-eligibility-wrapper h2 span {
    padding: 0rem 1rem;
    margin-top: 3rem;
    font-size: 1.5rem;
  }
} */
@media screen and (max-width: 991px) {
  .full .col-md-6.affordability-eligibility-form,
  .full .col-md-6.affordability-eligibility-form-summary {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .full .col-md-6.affordability-eligibility-form-summary {
    position: fixed;
    top: auto;
    bottom: 0px;
    z-index: 500;
    margin-bottom: 0px;
    display: block;
  }

  .col-md-12.affordability-eligibility-mobile-summary {
    display: block;
  }

  .finance-plus-widget-affordability-eligibility-form-summary {
    display: none;
  }

  .col-md-12.affordability-eligibility-mobile-summary {
    position: sticky;
    left: 0;
    bottom: 0;
    /* box-shadow: 0 -40px 20px -18px rgba(0, 0, 0, 0.2); */
    background: #ffffff;
    box-shadow: 0 1px 46px 0 rgba(0, 0, 0, 0.16);
    border-style: solid;
    border-width: 1px;
    border-color: #e8e9ea;
    border-radius: 5px;
  }

  .affordability-eligibility-mobile-summary-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .loan-amount-equity-contribution {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .loan-amount-equity-contribution .loan-amount {
    display: flex;
    flex-direction: column;
    /* align-items: stretch; */
    margin-top: 10px;
  }

  .loan-amount p {
    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(64, 64, 64, 0.5);
    font-family: "Droid Sans", sans-serif;
  }

  .loan-amount h2 {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: #404040;
    margin-top: -15px;
    font-family: "DM Sans", sans-serif;
  }

  .loan-amount h4 {
    margin-right: 0px;
    margin-bottom: 0px;
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: -5px;
  }

  .equity-contribution label {
    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(64, 64, 64, 0.5);
    margin-top: 12px;
    font-family: "Droid Sans", sans-serif;
  }

  .equity-contribution input {
    height: 35px;
    width: 100%;
  }
  .equity-contribution input:focus {
    box-shadow: none !important;
    border: 1px solid #136b3d;
  }

  .buy-this-property {
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #00b1ab;
    font-family: "Droid Sans", sans-serif;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
  }

  /* .full .finance-plus-widget-affordability-eligibility-form-summary {
    bottom: 0;
    z-index: 100;
  } */
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .mortgage-widget-wrapper {
    padding: 0;
  }
  .finance-plus-widget-affordability-eligibility-form-summary {
    padding: 0;
    border: none;
    position: sticky;
    /* bottom: 0px; */
    z-index: 500;
    bottom: -40px;
    /* order: 2; */
  }

  .loan-amount h4 {
    margin-top: 0;
  }

  .finance-plus-widget-affordability-eligibility-wrapper h2 span {
    font-size: 1.6rem;
  }

  .finance-plus-widget-affordability-eligibility-summary-content-header {
    padding: 0 1.5rem;
  }

  .finance-plus-widget-affordability-eligibility-summary-content-header-text {
    /* padding: 1.8rem 0 3rem; */
    padding: 2rem 0;
  }

  .finance-plus-widget-affordability-eligibility-summary-content-header-text p {
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
  }

  .finance-plus-widget-affordability-eligibility-summary-content-bottom-text {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .affordability-eligibility-mobile-summary-wrapper {
    padding: 1rem 0;
  }

  .loan-amount-equity-contribution {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  /* .equity-contribution input {
    width: 100%;
  } */

  .buy-this-property {
    margin: 10px 0;
    padding: 10px 20px;
  }

  .equity-contribution {
    width: 100% !important;
  }

  .equity-contribution input {
    height: 40px;
    margin-bottom: -10px;
  }

  .loan-amount.property-title,
  .divider {
    display: none;
  }

  .loan-amount p {
    margin-bottom: 0px;
  }

  .loan-amount h2 {
    margin-top: -5px;
    margin-bottom: -5px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 479px) {
  .finance-plus-widget-affordability-eligibility-form {
    padding: 0;
  }
  .property-funding-wrapper .view-matching-properties {
    font-size: 0.8rem;
  }

  .buy-this-property {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 320px) {
  .buy-this-property {
    font-size: 0.7rem;
  }
}

