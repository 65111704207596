.properties-status-overview-wrapper {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 16px;
}

.properties-status-overview-wrapper h5 + p {
  color: #999;
}

.properties-status-overview-wrapper [class*='-stats'] .indicator {
  height: 100px;
  width: 100px;
  border: 15px solid;
  border-radius: 50%;
}

.properties-status-overview-wrapper [class*='-stats'] .indicator  .h2 {
  font-weight: 800 !important;
  font-size: 1.8rem;
}

.properties-status-overview-wrapper [class*='-stats'] .indicator  + p {
  font-weight: 600 !important;
}

.properties-status-overview-wrapper [class*='-stats'] p {
  margin: 0px;
}

.properties-status-overview-wrapper .finished-stats .indicator {
  border-color: #2BB930;
}

.properties-status-overview-wrapper .under-construction-stats .indicator {
  border-color: #FF9E27;
  border-left-color: #eee;
}

.properties-status-overview-wrapper .off-plan-stats .indicator {
  border-color: #F25961;
  border-left-color: #eee;
  border-bottom-color: #eee;
  border-right-color: #eee;
}