.dashboard-wrapper .counts-summary-wrapper {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  column-gap: 30px;
  margin-right: 0px;
  padding-right: 0px;
}

.dashboard-wrapper .counts-summary-wrapper {
  grid-template-columns: repeat(1, 1fr);
}

.dashboard-wrapper .counts-summary-wrapper .item-1 {
  grid-column: 1 / 2;
}

.dashboard-wrapper .counts-summary-wrapper .item-2 {
  grid-column: 1 / 2;
}

.dashboard-wrapper .counts-summary-wrapper .item-3 {
  grid-column: 1 / 2;
}

.dashboard-wrapper .counts-summary-wrapper .item-4 {
  grid-column: 1 / 2;
}

.dashboard-wrapper .counts-summary-wrapper .label {
  font-size: .8rem;
}

.dashboard-wrapper .properties-status-overview-wrapper > h5 + p {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 24px;
  margin-top: -5px;
  margin-bottom: 20px;
  font-size: 12px;
}

.dashboard-wrapper .dashboard-stats {
  height: fit-content;
}

.dashboard-wrapper .dashboard-stats > div > div {
  height: 100%;
}

.dashboard-wrapper .dashboard-stats > div > div,
.dashboard-wrapper .by-cities-wrapper {
  box-shadow: 20px 15px 25px 5px rgba(0,0,0,0.05);
  border: 1px solid #cfcfcf;
  padding: 20px
}

.dashboard-wrapper .by-cities-wrapper {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 16px;
}

/* .dashboard-wrapper .by-cities-wrapper .by-city-wrapper:not(:nth-child(4n)) {
  border-right: 1px solid #ccc;
} */

.dashboard-wrapper .by-city-wrapper {
  height: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 576px) {
  .dashboard-wrapper .by-cities-wrapper .by-city-wrapper:not(:nth-child(2n)) {
    border-right: 1px solid #ddd;
  }
}

@media screen and (min-width: 768px) {
  .dashboard-wrapper .by-cities-wrapper .by-city-wrapper:not(:nth-child(4n)) {
    border-right: 1px solid #ddd;
  }
  
  .dashboard-wrapper .counts-summary-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-wrapper .counts-summary-wrapper .item-1 {
    grid-column: 1 / 2;
  }
  
  .dashboard-wrapper .counts-summary-wrapper .item-2 {
    grid-column: 2 / 3;
  }
  
  .dashboard-wrapper .counts-summary-wrapper .item-3 {
    grid-column: 1 / 2;
  }
  
  .dashboard-wrapper .counts-summary-wrapper .item-4 {
    grid-column: 2 / 3;
  }
}

@media screen and (min-width: 1350px) {
  .dashboard-wrapper .counts-summary-wrapper {
    grid-template-columns: repeat(15, 1fr);
  }

  .dashboard-wrapper .counts-summary-wrapper .item-1 {
    grid-column: 1 / 7;
  }
  
  .dashboard-wrapper .counts-summary-wrapper .item-2 {
    grid-column: 7 / 10;
  }
  
  .dashboard-wrapper .counts-summary-wrapper .item-3 {
    grid-column: 10 / 13;
  }
  
  .dashboard-wrapper .counts-summary-wrapper .item-4 {
    grid-column: 13 / 16;
  }
}
