.add-property-page-wrapper .card:not(:last-child) {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.add-property-page-wrapper .card:not(:first-child) {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.add-property-page-wrapper .card-header h5 {
  font-size: 1.5em;
  padding-top: 8px;
  padding-bottom: 8px;
}
