#lagos-state-app-authentication-wrapper.reset-password {
  padding-top: 135px;
}

.lagos-state-app-auth-reset-password {
  margin: 30px 0;
}

.lagos-state-app-reset-password-text-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.lagos-state-app-reset-password-text-wrapper svg {
  color: #fda42f;
  margin-top: -10px;
}

.lagos-state-app-reset-password-text-wrapper
  .lagos-state-app-reset-password-caption {
  font-size: 16px;
  margin-left: 15px;
  font-weight: 500;
}

.lagos-state-app-reset-password-text-wrapper svg {
  color: #fda42f;
  margin-top: -10px;
}

.lagos-state-app-reset-password-text-wrapper.verify svg {
  margin-top: -5px;
}

.lagos-state-app-reset-password-text-wrapper.verify
  .lagos-state-app-reset-password-caption {
  margin-left: 15px;
}

/* .code_group input.is-invalid,
.code_group input.form-control:invalid,
.code_group .form-control:valid {
  background-image: none !important;
} */

.code_group {
  display: flex;
  justify-content: center;
}

.code_group input {
  border: 0;
  border-bottom: 1px solid #a1a7ac;
  display: inline-block;
  width: 40px;
  font-size: 30px;
  color: #5f6771;
  padding: 0;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  height: 45px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-clip: padding-box;
  box-shadow: none;
  background-color: #fff;
}

.code_group input:not(:last-child) {
  margin-right: 7px;
}

.code_group input:focus {
  outline: none;
  border-color: #009d47;
}

.was-validated .code_group input:valid {
  border-color: #009d47;
}

.was-validated .code_group input:invalid {
  border-color: #dc3545;
}

.was-validated .code_group + div .invalid-feedback {
  display: flex !important;
  justify-content: center !important;
}
