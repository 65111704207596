.property-list-page-wrapper tr{
  height: 60px;
}

.property-list-page-wrapper .stat-wrapper {
  width: 100%;
}

@media screen and (min-width: 900px) {
  .property-list-page-wrapper .stat-wrapper {
    width: 50%;
  }
}