/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.form-control {
  color: #1c273c;
  font-weight: 500;
  border-width: 2px;
  border-color: #cdd4e0;
  height: 45px;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-clip: padding-box;
}

.lagos-state-app-authentication-form-wrapper .lagos-state-app-auth-submit-btn {
  padding: 10px !important;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #fda42f;
  padding: 6px 15px;
  border: 2px solid #fda42f;
  border-radius: 3px;
  cursor: pointer;
  color: #ffffff;
  box-shadow: 0 10px 14px rgba(240, 220, 134, 0.28);
}
.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-submit-btn:hover {
  border: 2px solid #000000;
  background-color: #000000;
  box-shadow: 0 10px 14px rgba(51, 49, 38, 0.28);
}

select.form-control:not([size]):not([multiple]) {
  height: 45px;
}

.form-control::placeholder,
select.form-control::placeholder {
  font-weight: 400;
  color: #97a3b9;
  opacity: 1;
}

.form-control:focus,
select.form-control:focus {
  box-shadow: none;
  border-color: #009d47;
}

.lagos-state-app-auth-form {
  padding: 20px 0;
}

.intl-tel-input.allow-dropdown {
  width: 100%;
}

.password-show-hide {
  z-index: 3;
}

.password-show-hide svg {
  color: #fda42f;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 14px;
  font-size: 18px;
}

.password-strength-meter {
  text-align: right;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 80px;
  height: 6px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 3px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #f25f5c;
}
.strength-Fair::-webkit-progress-value {
  background-color: #ffe066;
}
.strength-Good::-webkit-progress-value {
  background-color: #247ba0;
}
.strength-Strong::-webkit-progress-value {
  background-color: #70c1b3;
}

/* Login Page */

/* .lagos-state-app-auth-forgot-password {
} */

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-forgot-password {
  text-align: left;
  font-size: 17px;
  margin: 10px auto;
}

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-forgot-password
  a {
  color: #97a3b9;
  font-weight: 700;
  text-decoration: none;
}

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-forgot-password
  a:hover {
  color: #009d47;
}

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-register-option {
  /* border-top: 1px solid #d9dadc; */
  /* border-bottom: 1px solid #d9dadc; */
  padding: 20px 0 0px;
}

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-register-option
  p {
  font-size: 17px;
  font-weight: 700;
  color: #112211;
  text-align: center;
}

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-register-wrapper {
  padding: 10px !important;
  width: 70%;
  font-weight: 600;
  font-size: 15px;
  background-color: transparent;
  border: 2px solid #fda42f;
  border-radius: 2px;
  cursor: pointer;
  margin: 20px auto;
  text-align: center;
  text-transform: uppercase;
  display: block;
}

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-register-option
  .lagos-state-app-disclaimer {
  color: #7987a1;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}

/* Reset Password */

.lagos-state-app-auth-reset-password .lagos-state-app-auth-reset-password-btn {
  padding: 10px !important;
  width: 50%;
  font-weight: 600;
  font-size: 14px;
  border: 2px solid #fda42f;
  background-color: #fda42f;
  border-radius: 3px;
  cursor: pointer;
  margin: 20px auto;
  text-align: center;
  text-transform: uppercase;
  display: block;
  color: #fff;
  box-shadow: 0 10px 14px rgba(240, 220, 134, 0.28);
}
.lagos-state-app-auth-reset-password
  .lagos-state-app-auth-reset-password-btn:hover {
  border: 2px solid #000000;
  background-color: #000000;
  box-shadow: 0 10px 14px rgba(51, 49, 38, 0.28);
}

.lagos-state-app-auth-reset-password .lagos-state-app-auth-account-holder {
  text-align: center;
  color: #3f4547;
  font-weight: 700;
  font-size: 17px;
}

.lagos-state-app-auth-reset-password .lagos-state-app-auth-account-holder a,
.lagos-state-app-auth-reset-password .lagos-state-app-auth-account-holder span
{
  color: #009d47;
  margin-left: 5px;
}

/* Verify Code */
.code_group {
  margin-bottom: 20px;
  text-align: center;
}

.code_group .form-control {
  border: 0;
  border-bottom: 1px solid #a1a7ac;
  border-radius: 0;
  display: inline-block;
  width: 40px;
  font-size: 30px;
  color: #5f6771;
  padding: 0;
  margin-right: 7px;
  text-align: center;
  line-height: 1;
}
