.photos-gallery-form-group .input-button-wrapper {
  width: 180px;
  height: 40px;
}

.photos-gallery-form-group .featured-image input {
  z-index: 2;
}

.photos-gallery-form-group .image-preview-wrapper,
.featured-image .image-wrapper {
  height: 100px;
  border: 1px solid #999;
  border-radius: 4px;
  overflow-y: hidden;
}

.photos-gallery-form-group .input-button-wrapper > *,
.photos-gallery-form-group .featured-image > * {
  width: 100%;
  height: 100%;
}

.photos-gallery-form-group .featured-image .hover-show-text {
  display: none;
}

.photos-gallery-form-group .featured-image:hover .image-wrapper {
  background-color: #88888833;
}

.photos-gallery-form-group .featured-image:hover .hover-show-text {
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-15px);
} 

.photos-gallery-form-group input {
  display: block;
  z-index: 1;
  opacity: 0;
}

.photos-gallery-form-group span {
  border: 1px solid #007bff;
}