@font-face {
  font-family: "Fa brands 400";
  src: url("../../resources/fonts/fa-brands-400.woff2") format("woff2"),
    url("../../resources/fonts/fa-brands-400.eot") format("embedded-opentype"),
    url("../../resources/fonts/fa-brands-400.woff") format("woff"),
    url("../../resources/fonts/fa-brands-400.ttf") format("truetype"),
    url("../../resources/fonts/fa-brands-400.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Fa solid 900";
  src: url("../../resources/fonts/fa-solid-900.woff2") format("woff2"),
    url("../../resources/fonts/fa-solid-900.eot") format("embedded-opentype"),
    url("../../resources/fonts/fa-solid-900.woff") format("woff"),
    url("../../resources/fonts/fa-solid-900.ttf") format("truetype"),
    url("../../resources/fonts/fa-solid-900.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Fa duotone 900";
  src: url("../../resources/fonts/fa-duotone-900.woff2") format("woff2"),
    url("../../resources/fonts/fa-duotone-900.eot") format("embedded-opentype"),
    url("../../resources/fonts/fa-duotone-900.woff") format("woff"),
    url("../../resources/fonts/fa-duotone-900.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Fa 300";
  src: url("../../resources/fonts/fa-light-300.woff2") format("woff2"),
    url("../../resources/fonts/fa-light-300.eot") format("embedded-opentype"),
    url("../../resources/fonts/fa-light-300.woff") format("woff"),
    url("../../resources/fonts/fa-light-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Fa 400";
  src: url("../../resources/fonts/fa-regular-400.woff2") format("woff2"),
    url("../../resources/fonts/fa-regular-400.eot") format("embedded-opentype"),
    url("../../resources/fonts/fa-regular-400.woff") format("woff"),
    url("../../resources/fonts/fa-regular-400.ttf") format("truetype"),
    url("../../resources/fonts/fa-regular-400.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

/* General Styles */
body {
  font-family: "DM Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

a:active,
a:focus {
  color: #136b3c !important;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "DM Serif Display", sans-serif;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "DM Serif Display", sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 23px;
}

.nav-wrapper {
  display: -ms-grid;
  display: grid;
  min-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  justify-items: start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 3.5fr 1fr;
  grid-template-columns: 1fr 3.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #fff;
}

.hero-section {
  display: -ms-grid;
  display: grid;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-areas: ". hero-left hero-left hero-right hero-right hero-right" ". hero-left hero-left hero-right hero-right hero-right";
  -ms-grid-columns: 0.5fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto 20px auto;
  grid-template-rows: auto auto;
}

.nav-items {
  font-family: "DM Sans", sans-serif;
  color: rgba(64, 64, 64, 0.61);
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.nav-items:hover {
  color: #404040;
}

.nav-items.o--current {
  color: #dc7e4b;
}

.nav-toggle-icon {
  font-size: 16px;
  font-family: "Fa solid 900", sans-serif;
}

.dropdown-item {
  font-size: 12px !important;
  color: rgba(64, 64, 64, 0.61);
  font-size: 11px;
  font-family: "DM Sans", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.dropdown-item :hover {
  color: #136b3c !important;
}

.site-heading {
  margin-bottom: 48px;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
  font-size: 90px;
  line-height: 80px;
}

.site-heading.primary {
  font-size: 100px;
  line-height: 85px;
}

.site-heading.sec {
  font-size: 45px;
  line-height: 45px;
  text-align: left;
}

.hero-right {
  display: block;
  width: 100%;
  padding-right: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.intro-section {
  display: -ms-grid;
  display: grid;
  min-height: 0px;
  margin-top: 22px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "partner-logo partner-logo partner-logo partner-logo partner-logo partner-logo" ". company-highlights company-highlights intro-title intro-title ." ". company-highlights company-highlights intro-title intro-title ." ". company-highlights company-highlights . . .";
  -ms-grid-columns: 0.5fr 16px minmax(200px, 1fr) 16px minmax(200px, 1fr) 16px
    minmax(200px, 1fr) 16px minmax(200px, 1fr) 16px 0.5fr;
  grid-template-columns:
    0.5fr minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr)
    minmax(200px, 1fr) 0.5fr;
  -ms-grid-rows: auto 16px auto 16px auto 16px auto;
  grid-template-rows: auto auto auto auto;
  border-top: 1px none #c4c4c4;
  border-bottom: 1px none #c4c4c4;
  background-color: #fafafa;
}

.company-highlight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 23px 5px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #e8e9ea;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 20px 16px -18px rgba(0, 0, 0, 0.24);
}

.highlight-icon {
  position: static;
  left: 233px;
  top: 17px;
  width: 60px;
  margin-right: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e9ea;
}

.highlight-heading {
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
  text-align: left;
  letter-spacing: 1px;
}

.highlight-deets {
  font-size: 14px;
}

.company-highlight-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: 60px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.page-wrapper {
  display: -ms-grid;
  display: grid;
  padding-right: 0px;
  padding-left: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.highlight-deets-wrapper {
  padding-top: 20px;
  border-top: 0px none #e8e9ea;
  border-left: 0px none #e8e9ea;
}

.intro-title-description {
  margin-top: 20px;
  margin-bottom: 20px;
}

.partner-logo {
  margin-right: 10px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.partner-logo:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.partner-logo-img:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.project-section {
  display: -ms-grid;
  display: grid;
  padding-top: 50px;
  padding-bottom: 50px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: ". . . . . ." ". our-projects projects-deets projects-deets . ." "properties properties properties properties properties properties" "properties properties properties properties properties properties";
  -ms-grid-columns: 0.5fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto 16px auto 16px auto 16px auto 16px auto;
  grid-template-rows: auto auto auto auto auto;
}

.property-wrapper {
  display: -ms-grid;
  display: grid;
  overflow: scroll;
  width: 100%;
  margin-top: 44px;
  margin-bottom: 44px;
  padding-left: 10%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  grid-auto-flow: column dense;
  grid-auto-columns: minmax(350px, 1fr);
  grid-column-gap: 35px;
  grid-row-gap: 16px;
  -ms-grid-columns: minmax(350px, 1fr);
  grid-template-columns: minmax(350px, 1fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.property-card {
  min-width: 300px;
  margin-right: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
  border-radius: 10px;
  background-color: #fff;
  cursor: default;
}

.property-image-holder {
  height: 200px;
  border-radius: 10px 10px 0px 0px;
  /* background-image: url("../../resources/modern-building-against-sky-323780.jpg"); */
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-name-holder {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
}

.card-price-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9px;
  margin-bottom: 0px;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-property-status {
  margin-right: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #136b3d;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  font-size: 10px;
  letter-spacing: 0.5px;
}

.property-card-extras {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #adadad;
}

.card-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  /* font-size: 20px;
  font-weight: 400; */
  font-size: 17px;
  font-weight: 500;
}

.card-bed-bath {
  border-left: 1px none #c4c4c4;
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card-location {
  font-family: "Droid Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card-price {
  font-family: "DM Sans", sans-serif;
  color: #136b3d;
  font-weight: 700;
}

.separator {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #e8e9ea;
}

.advantage-section {
  display: -ms-grid;
  display: grid;
  padding-top: 30px;
  padding-bottom: 70px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: ". . . . ." ". . advantage-title advantage-title ." ". advantage-count advantage-count advantage-count advantage-count" ". steps steps steps steps";
  -ms-grid-columns: 0.5fr 16px 1fr 16px 1fr 16px 1fr 16px 1fr 16px 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto 16px auto 16px auto 16px auto 16px auto;
  grid-template-rows: auto auto auto auto auto;
  background-color: #fafafa;
  text-transform: capitalize;
}

.step-wrapper {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.step-wrapper.mid {
  border-right: 1px solid #e8e9ea;
}

.step-icon {
  display: block;
  width: 70%;
  max-width: 70%;
  margin-right: auto;
  margin-bottom: -27px;
  margin-left: auto;
}

.step-details {
  padding: 9px;
}

.step-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "DM Serif Display", sans-serif;
  font-size: 20px;
  line-height: 22px;
}

.step-description {
  font-family: "DM Sans", sans-serif;
}

.step-count {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 45px;
  margin-bottom: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.step-count-items {
  position: relative;
  z-index: 10;
  width: 50px;
  height: 50px;
  margin-top: 10px;
  padding: 12px 0px 0px;
  border: 1px solid #e8e9ea;
  border-radius: 100%;
  background-color: #136b3c;
  font-family: "DM Serif Display", sans-serif;
  color: #fff;
  line-height: 24px;
  text-align: center;
}

.footer {
  display: -ms-grid;
  display: grid;
  min-height: 400px;
  padding-top: 75px;
  padding-bottom: 75px;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-areas: ". . . . ." ". footer-widget-1 footer-widget-2 footer-widget-3 footer-widget-4" ". . copyright copyright .";
  -ms-grid-columns: 0.5fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto 20px auto 20px auto;
  grid-template-rows: auto auto auto;
  background-color: #000;
  position: relative !important;
  /* margin-top: -60px; */
}

.footer-widget {
  margin-right: 10px;
}

.footer-social-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.footer-social-link {
  margin-right: 20px;
  font-family: "Fa brands 400", sans-serif;
  color: #fefefe;
  font-size: 25px;
  text-decoration: none;
}

.footer-widget-heading {
  margin-top: 0px;
  font-family: "DM Serif Display", sans-serif;
  color: #fff;
  letter-spacing: 1px;
}

.footer-link-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer-link {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "DM Sans", sans-serif;
  color: #949494;
  text-decoration: none;
}

.footer-link-holder .footer-link:hover {
  color: #136b3c !important;
}

.copyright {
  margin-top: 48px;
  margin-bottom: 0px;
  border-top: 1px solid #000;
  font-family: "DM Sans", sans-serif;
  color: #949494;
  font-size: 14px;
  font-weight: 400;
}

.hero-image {
  width: 95%;
  height: 470px;
}

.archive-wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 45px;
  margin-bottom: 45px;
  padding-right: 20px;
  padding-left: 20px;
  grid-auto-flow: row;
  /* grid-auto-columns: minmax(350px, 1fr); */
  grid-column-gap: 30px;
  grid-row-gap: 35px;
  /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.sorting-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #f7f7f7;
}

.filter-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "DM Sans", sans-serif;
}

.filter-input {
  margin-right: 0px;
  margin-bottom: 0px;
  padding-left: 12px;
  border: 0px solid #000;
  border-radius: 0px;
  background-color: transparent;
}

.filter-input-btn {
  padding-right: 30px;
  padding-left: 30px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 5px;
  background-color: #136b3c;
}

.filter-input-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: 10px;
  padding-left: 7px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.filter-input-icon {
  position: static;
  left: 28px;
  top: 27px;
  z-index: 5;
  padding-right: 7px;
  clear: none;
  border-right: 1px solid #c4c4c4;
  font-family: "Fa 400", sans-serif;
  color: #c4c4c4;
  line-height: 14px;
}

.btn-icon {
  margin-right: 8px;
  font-family: "Fa solid 900", sans-serif;
}

.property-details-wrapper {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.property-gallery-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  /* background-attachment: scroll; */
  height: 100%;
}
/* 
.property-gallery-image.one {
  height: 100%;
  background-image: url("../../resources/architecture-driveway-home-house-259751.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: scroll;
} */

/* .property-gallery-image.two {
  height: 100%;
  background-image: url("../../resources/architecture-driveway-home-house-259751.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-gallery-image.three {
  height: 100%;
  background-image: url("../../resources/architecture-driveway-home-house-259751.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-gallery-image.four {
  height: 100%;
  background-image: url("../../resources/architecture-driveway-home-house-259751.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-gallery-image.five {
  height: 100%;
  background-image: url("../../resources/architecture-driveway-home-house-259751.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-gallery-image.six {
  height: 100%;
  background-image: url("../../resources/architecture-driveway-home-house-259751.jpg");
  background-position: 50% 50%;
  background-size: cover;
} */

.property-info-wrapper {
  display: -ms-grid;
  display: grid;
  margin-bottom: 60px;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  grid-template-areas: ". details-info-left details-info-left details-info-left details-info-right";
  -ms-grid-columns: 0.5fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.property-info {
  padding-right: 60px;
}

.info-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.name-icon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.property-name {
  margin-top: 10px;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-weight: 400;
}

.info-icon {
  font-family: "Fa 400", sans-serif;
  color: #6c6c6c;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}

.info-icon.img {
  height: 40px;
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.7;
  margin-bottom: 10px;
}

.property-info-icon-wrapper {
  width: 100px;
  /* width: 150px; */
  margin-left: 20px;
  padding: 19px 11px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border: 1px solid #e8e9ea;
  border-radius: 5px;
  background-color: #fff;
}

.property-info-icon-wrapper.amenities {
  margin-right: 40px;
  margin-bottom: 40px;
  margin-left: 0px;
  box-shadow: none;
}

.info-icon-name {
  margin-top: 6px;
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.name-icon-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.info-section-title {
  margin-top: 0px;
  margin-bottom: 20px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  font-size: 16px;
  letter-spacing: 1px;
}

.info-description {
  font-family: "DM Sans", sans-serif;
  color: #696969;
  font-size: 16px;
  line-height: 25px;
}

.view-on-map {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.view-on-map-icon {
  margin-right: 15px;
  font-family: "Fa solid 900", sans-serif;
  color: #dc7e4b;
  font-size: 30px;
  line-height: 40px;
}

.view-on-map-link {
  font-family: "DM Sans", sans-serif;
  color: #696969;
  font-size: 16px;
  line-height: 25px;
  text-decoration: none;
}

.amenities-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.property-image-slide {
  width: 33.3vw;
  margin-right: 5px;
}

.dual-cta-wrapper {
  display: -ms-grid;
  display: grid;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dual-cta-wrapper.advantage {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.partner-logos-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 95px;
  padding-top: 5px;
  padding-bottom: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e8e9ea;
  border-bottom: 1px solid #e8e9ea;
  background-color: #fff;
}

.steps {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 60px;
  grid-template-areas: ". . ." "dual-cta dual-cta dual-cta";
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto 60px auto;
  grid-template-rows: auto auto;
}

.mortgage-deets-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e8e9ea;
}

.mortgage-heading {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  color: #adadad;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.card-bed-bath-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 11px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e8e9ea;
}

.card-inner-divider {
  width: 1px;
  height: 20px;
  background-color: #e8e9ea;
}

.card-inner-divider.long {
  height: 40px;
}

.card-icon {
  margin-right: 9px;
  font-family: "Fa 400", sans-serif;
  color: #b1b1b1;
}

.card-inner-side {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.card-inner-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mortage-value {
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

.mortgage-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mortgage-freq {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.repayment-heading {
  margin-bottom: 0px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-family: "DM Sans", sans-serif;
  color: #adadad;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.repayment-period {
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

.repayment-freq {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.repayment-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.project-card-cta {
  width: 100%;
  padding: 11px 30px;
  border-top: 1px solid #e8e9ea;
  border-radius: 0px 0px 10px 10px;
  background-color: #fff;
  font-family: "Droid Sans", sans-serif;
  color: #136b3d;
  font-size: 12px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.project-card-cta:hover {
  background-color: #136b3d;
  color: #fff;
}

.section-desc {
  font-family: "DM Sans", sans-serif;
}

.property-image-holder-2 {
  height: 200px;
  border-radius: 10px 10px 0px 0px;
}

.property-image-holder-2.one {
  background-image: url("../../resources/modern-building-against-sky-323780.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-image-holder-2.two {
  background-image: url("../../resources/staircase-area-2121121.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-image-holder-2.three {
  background-image: url("../../resources/white-2-storey-house-near-trees-1115804.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-image-holder-2.four {
  background-image: url("../../resources/brown-wooden-center-table-584399.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-image-holder-2.five {
  background-image: url("../../resources/architecture-driveway-home-house-259751.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-image-holder-2.six {
  background-image: url("../../resources/modern-building-against-sky-323780.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-image-holder-2.seven {
  background-image: url("../../resources/brown-wooden-center-table-584399.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.property-image-holder-2.eight {
  background-image: url("../../resources/staircase-area-2121121.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.purchase-option-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.purchase-option-toggle-wrapper {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.purchase-option-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  padding: 25px 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 3px solid #e8e9ea;
  border-radius: 10px;
  background-color: #fff;
  color: #6c6c6c;
  text-decoration: none;
}

.purchase-option-toggle:hover,
.purchase-option-toggle.current {
  border-color: #136b3d;
  background-color: #136b3d;
  box-shadow: 3px 40px 20px -7px rgba(0, 0, 0, 0.2);
  color: #e8e9ea;
}

.option-name {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  line-height: 19px;
  text-align: left;
  text-transform: uppercase;
}

.purchase-option-icon {
  margin-right: 16px;
  font-family: "Fa 300", sans-serif;
  font-size: 45px;
  line-height: 45px;
}

.mortgage-widget-wrapper {
  margin-top: 20px;
  padding: 19px;
  border: 3px solid #e8e9ea;
  border-radius: 10px;
}

.purchase-form {
  display: -ms-grid;
  display: grid;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.purchase-form-input-label {
  margin-top: 0px;
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  text-align: left;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.purchase-form-input {
  border-radius: 5px;
}

.success-message {
  border-radius: 5px;
  background-color: #dc7e4b;
  color: #fff;
}

.installment-form-wrapper {
  margin-top: 20px;
  padding: 19px;
  border: 3px solid #e8e9ea;
  border-radius: 10px;
}

.installment-form-wrapper p {
  font-family: "DM Sans", sans-serif;
  color: #696969;
  font-size: 16px;
  line-height: 25px;
}

.installment-form-wrapper ul {
  list-style: none;
  padding: 0 19px;
}

.installment-form-wrapper ul li {
  margin: 0.6rem 0;
  font-family: "DM Sans", sans-serif;
  color: #696969;
  font-size: 0.9rem;
  line-height: 25px;
}

.installment-form-wrapper ul li strong {
  color: #136b3d;
  font-family: "DM Serif Display", sans-serif;
  letter-spacing: 0.7px;
}

.property-price-box {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 200;
  margin-top: 36px;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #e8e9ea;
  border-radius: 5px;
}

.property-price-box:hover {
  box-shadow: 0 40px 20px -18px rgba(0, 0, 0, 0.2);
}

.price-from {
  font-family: "Droid Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.property-price {
  margin-top: 0px;
  font-family: "DM Sans", sans-serif;
  font-size: 28px;
  font-weight: 400;
}

.property-mortage-highlight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding: 16px 0px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px solid #e8e9ea;
}

.price-card-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.property-mortgage-heading {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  color: #adadad;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.property-mortage-value {
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

.property-mortgage-freq {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.property-repayment-heading {
  margin-bottom: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  font-family: "DM Sans", sans-serif;
  color: #adadad;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.property-repayment-period {
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

.property-repayment-freq {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.title-doc-heading {
  margin-right: 5px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  color: #adadad;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.title-doc {
  margin-right: 0px;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}

.title-doc-icon {
  font-family: "Fa solid 900", sans-serif;
  color: #3f9c6b;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.side-bar-btn {
  width: 100%;
  margin-top: 16px;
  padding: 15px 13px;
  border-radius: 5px;
  background-color: #136b3d;
  font-family: "Droid Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.company-highlight-image {
  width: 100%;
}

.nav-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid #e8e9ea;
  box-shadow: none;
}

.site-button {
  padding: 15px 30px;
  border-radius: 5px;
  background-color: #136b3d;
  font-family: "Droid Sans", sans-serif;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

.site-button.header-action {
  padding: 9px 24px;
  background-color: #dc7e4b;
  font-family: "Droid Sans", sans-serif;
}

.site-button.alt {
  border: 1px solid #136b3d;
  background-color: #fff;
  color: #136b3d;
}

.site-heading-adv {
  color: #404040;
  font-size: 45px;
  line-height: 45px;
  text-align: center;
}

.site-paragraph {
  width: 70%;
  margin-bottom: 35px;
  font-family: "DM Sans", sans-serif;
  color: #696969;
  line-height: 20px;
}

.site-paragraph.wide {
  width: 100%;
}

.finance-widget-wrapper {
  min-height: 400px;
  padding-right: 10px;
  padding-left: 10px;
}

.property-slider {
  height: 450px;
  background-color: #fff;
}

.slider-icons {
  font-family: "Fa solid 900", sans-serif;
  font-size: 28px;
}

.property-features-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.property-features-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.hr-line {
  position: absolute;
  z-index: 1;
  width: 55%;
  height: 1px;
  background-color: #e8e9ea;
}

/* Other Pages */
#company-profile {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 80px 0;
  background-image: url("../../resources/about-banner-img.JPG");
}
.company-profile-wrapper {
  padding: 24px 60px 80px;
  background: #fff;
}

#company-profile .company-profile-wrapper > span {
  color: #136b3d;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 25px;
  font-size: 40px;
  line-height: 85px;
  margin-bottom: 48px;
  font-family: "DM Serif Display", sans-serif;
}

.company-profile-wrapper .title {
  font-family: "DM Serif Display", sans-serif;
  font-size: 80px;
  line-height: 45px;
  color: #404040;
}

.company-profile-wrapper p {
  font-family: "DM Sans", sans-serif;
  color: #696969;
  line-height: 27px;
  font-size: 16px;
}

.company-profile-list-services {
  list-style: none;
  margin-bottom: 1rem !important;
  margin-top: 1.5rem !important;
}

.company-profile-list-services li {
  font-family: "DM Sans", sans-serif;
  color: #696969;
  line-height: 27px;
  font-size: 16px;
  font-weight: 400;
}

.company-profile-list-services li::before {
  content: "-";
  color: #136b3d;
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
}

/* Company Members */

#company-team {
  padding: 2rem 0;
}

.company-team-wrapper {
  padding: 0.5rem 0 3rem;
}

.company-team-wrapper h2 {
  font-family: "DM Serif Display", sans-serif;
  font-size: 35px;
  text-align: left;
  color: #404040;
  margin-bottom: 3rem;
}

.team-container {
  border-radius: 5px;
  border: 1px solid #efefef;
  text-align: center;
  padding-top: 20px;
  color: #444;
  transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.team-container:hover {
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  border: none;
  transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.team-container .team-img {
  width: 150px;
  height: 153px;
  display: block;
  margin: 0px auto;
  border: 1.5px solid #fafafa;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 0px;
  border-radius: 500px;
}

.team-container .team-info {
  padding: 10px;
  padding-bottom: 10px;
  display: block;
}

.team-info .team-name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 5px;
  font-family: "DM Serif Display", sans-serif;
}

.team-info .team-position {
  font-size: 1.2em;
  font-weight: 400;
  line-height: 120%;
  font-family: "DM Sans", sans-serif;
  color: #696969;
}

.team-social-media {
  text-align: center;
  padding-bottom: 0.5rem !important;
}

.team-social-media svg {
  margin-bottom: 20px;
}

/* Award and honors */
#awards-honorary {
  padding: 2rem 0 4rem;
  background: #fafafa;
}

#awards-honorary .header-text {
  text-align: center;
  font-size: 40px;
  line-height: 85px;
  margin-bottom: 48px;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
  text-transform: uppercase;
}

.award-honorary-list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background: #fff;
  padding: 10px;
}

.award-image-wrapper {
  align-content: center;
  height: 106px;
  line-height: 126px;
  border: 1px solid rgba(107, 11, 11, 0.05);
  margin: 7px auto;
  padding: 7px;
  background: #fefefe;
  overflow: hidden;
  width: 70%;
  display: flex;
  /* justify-content: space-between; */
}

.award-image-wrapper img {
  vertical-align: unset;
  object-fit: contain;
}

.award-honorary-text-wrapper {
  margin-left: 30px;
}

.award-honorary-text-wrapper h4 {
  /* padding-bottom: 15px; */
  font-size: 1.3rem;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
}

.award-honorary-text-wrapper h4::after {
  content: "";
  border-top: 2px solid rgb(19, 108, 62, 0.4);
  display: block;
  width: 25px;
  margin: 8px 0;
}

.award-honorary-text-wrapper p {
  font-size: 1rem;
  font-family: "DM Sans", sans-serif;
  color: #404040;
  text-align: left !important;
}

/* Work with us */
#work-with-us {
  padding: 2rem 0 4rem;
  background: #ffffff;
}

#work-with-us .header-text {
  text-align: center;
  font-size: 40px;
  line-height: 85px;
  margin-bottom: 48px;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
  text-transform: uppercase;
}

/* .working-with-us-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */

.work-with-us-overview h2 {
  font-size: 1.9rem;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
}

.work-with-us-overview h2::after {
  content: "";
  border-top: 2px solid rgb(19, 108, 62, 0.4);
  display: block;
  width: 50px;
  margin: 8px 0;
}

.work-with-us-overview p {
  font-size: 1rem;
  font-family: "DM Sans", sans-serif;
  color: #404040;
  text-align: left !important;
}

.work-with-us-objectives {
  background: rgb(199, 250, 223, 0.385);
  padding: 10px 30px;
  border-radius: 10px;
  align-self: center;
  display: block;
}

#about-us-page {
  padding: 2rem 0;
  background: #f3f3f3;
}

.about-us-page-sub-header-text h2 {
  font-family: "DM Serif Display", sans-serif;
  font-size: 45px;
  line-height: 45px;
  text-align: left;
  color: #404040;
}

#about-us-page-who-we-are {
  padding: 1rem 0 4rem;
  background: #ffffff;
}

.about-us-page-who-we-are-text h2 {
  font-family: "DM Serif Display", sans-serif;
  font-size: 35px;
  text-align: left;
  color: #404040;
}

.about-us-page-who-we-are-text p {
  font-family: "DM Sans", sans-serif;
  color: #696969;
  line-height: 27px;
  font-size: 16px;
  margin-top: 20px;
}

.about-us-page-who-we-are-shape-placeholder {
  background: #c4c4c4;
  border-radius: 10px;
  box-shadow: 0 40px 20px -18px rgba(0, 0, 0, 0.2);
}

.about-us-page-who-we-are-shape-placeholder
  .about-us-page-who-we-are-shape-placeholder-shape {
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
  background-position: 50%;
  border-radius: 10px;
}

.about-us-page-mission-text,
.about-us-page-vision-text {
  padding: 1rem 3rem;
  background: rgb(199, 250, 223, 0.685);
  margin-top: 3rem;
  border-radius: 10px;
}

.about-us-page-mission-text h2,
.about-us-page-vision-text h2 {
  font-family: "DM Serif Display", sans-serif;
  font-size: 35px;
  text-align: left;
  color: #404040;
  border-bottom: 4px solid rgb(19, 108, 62, 0.4);
  padding-bottom: 15px;
}

.about-us-page-mission-text p,
.about-us-page-vision-text p {
  font-family: "DM Sans", sans-serif;
  /* color: #696969; */
  color: #404040;
  line-height: 27px;
  font-size: 16px;
  margin-top: 20px;
}

#about-us-page-team {
  padding: 2rem 0;
  background: #f3f3f3;
}

.about-us-page-team-wrapper h2 {
  font-family: "DM Serif Display", sans-serif;
  font-size: 35px;
  text-align: center;
  color: #404040;
}

.about-us-page-team-wrapper p {
  font-family: "DM Sans", sans-serif;
  color: #696969;
  line-height: 27px;
  font-size: 16px;
  margin-top: 20px;
}

@media screen and (max-width: 991px) {
  .nav-wrapper {
    -ms-grid-columns: 1fr 0.5fr 1fr;
    grid-template-columns: 1fr 0.5fr 1fr;
  }
  .hero-section {
    display: -ms-grid;
    display: grid;
    width: 100%;
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-items: stretch;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas: ". hero-right" ". hero-left";
    -ms-grid-columns: 0.25fr 20px 2.5fr 20px 0.25fr;
    grid-template-columns: 0.25fr 2.5fr 0.25fr;
    -ms-grid-rows: auto 20px auto;
    grid-template-rows: auto auto;
  }
  .site-heading.primary {
    font-size: 90px;
  }
  .hero-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .intro-section {
    padding-right: 20px;
    padding-left: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas: "partner-logos partner-logos partner-logos" ". intro-title ." ". company-highlight-image .";
    -ms-grid-columns: 0.25fr 20px 2.5fr 20px 0.25fr;
    grid-template-columns: 0.25fr 2.5fr 0.25fr;
    -ms-grid-rows: auto 20px auto;
    grid-template-rows: auto auto;
  }
  .company-highlight-holder {
    display: -ms-grid;
    display: grid;
    margin-right: 0px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .partner-logo {
    width: auto;
    height: 35px;
    margin-right: 25px;
  }

  .partner-logo-img {
    width: auto;
    height: 35px;
    margin-right: 25px;
  }

  .project-section {
    padding-right: 20px;
    padding-left: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas: ". project-section-heading ." ". project-section-deets ." ". property-wrapper property-wrapper";
    -ms-grid-columns: 0.25fr 20px 2.5fr 20px 0.25fr;
    grid-template-columns: 0.25fr 2.5fr 0.25fr;
    -ms-grid-rows: auto 20px auto 20px auto;
    grid-template-rows: auto auto auto;
  }
  .property-wrapper {
    padding-left: 0%;
  }
  .separator {
    display: none;
  }
  .advantage-section {
    display: -ms-grid;
    display: grid;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas: ". advantage-title advantage-title" ". advantage-count advantage-count" ". steps steps";
    -ms-grid-columns: 0.25fr 20px 1.25fr 20px 1.25fr 20px 0.25fr;
    grid-template-columns: 0.25fr 1.25fr 1.25fr 0.25fr;
    -ms-grid-rows: auto 20px auto 20px auto 20px auto;
    grid-template-rows: auto auto auto auto;
  }
  .step-wrapper {
    padding-right: 40px;
    padding-left: 40px;
  }
  .footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 35px;
    padding-left: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-widget.two {
    margin-top: 34px;
    margin-bottom: 34px;
  }
  .hero-image {
    display: block;
    width: 100%;
    text-align: center;
  }
  .archive-wrapper {
    margin-top: 40px;
    grid-auto-columns: minmax(250px, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .sorting-bar {
    position: -webkit-sticky;
    position: sticky;
    top: auto;
    bottom: 0px;
    z-index: 500;
    box-shadow: 0 30px 20px 11px rgba(0, 0, 0, 0.59);
    font-family: "Droid Sans", sans-serif;
    font-size: 14px;
  }
  .filter-form {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .property-info-wrapper {
    padding-right: 54px;
    padding-left: 54px;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas: "details-info-left" "details-info-right";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 20px auto;
    grid-template-rows: auto auto;
  }
  .property-info {
    padding-right: 0px;
  }
  .property-image-slide {
    width: 100%;
  }
  .dual-cta-wrapper.advantage {
    width: 100%;
  }
  .partner-logos-wrapper {
    position: -webkit-sticky;
    position: sticky;
    overflow: scroll;
    margin-right: -19px;
    margin-left: -22px;
    padding-right: 11px;
    padding-left: 11px;
  }
  .steps {
    grid-row-gap: 30px;
    grid-template-areas: "." "." "." "dual-cta";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 30px auto 30px auto 30px auto;
    grid-template-rows: auto auto auto auto;
  }
  .repayment-wrapper {
    display: none;
  }
  .price-box-inspection-wrapper {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    background-color: #fff;
    box-shadow: 0 -40px 20px -18px rgba(0, 0, 0, 0.2);
  }
  .property-price-box {
    display: -ms-grid;
    display: grid;
    margin-top: 0px;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .property-price-box:hover {
    box-shadow: none;
  }
  .property-mortage-highlight {
    border-top-style: none;
  }
  .property-repayment-heading {
    display: none;
  }
  .nav-menu-holder {
    background-color: #fff;
    box-shadow: 0 20px 16px -16px rgba(0, 0, 0, 0.26);
  }
  .site-paragraph {
    width: 100%;
  }
  .hr-line {
    width: 55%;
  }
}

@media screen and (max-width: 767px) {
  .hero-section {
    padding-right: 0px;
    padding-left: 0px;
  }
  .site-heading.primary {
    font-size: 80px;
    line-height: 70px;
  }
  .intro-section {
    padding-right: 0px;
    padding-left: 0px;
    grid-template-areas: "partner-logos partner-logos partner-logos" ". intro-title ." ". company-highlight-image .";
  }
  .company-highlight-holder {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .project-section {
    padding-right: 0px;
    padding-left: 0px;
    -ms-grid-columns: 0.25fr 2.5fr 0.25fr;
    grid-template-columns: 0.25fr 2.5fr 0.25fr;
  }
  .advantage-section {
    grid-template-areas: ". advantage-title" ". advantage-count" ". steps" ". steps" ". steps" ". steps";
    -ms-grid-columns: 0.25fr 2.5fr 0.25fr;
    grid-template-columns: 0.25fr 2.5fr 0.25fr;
    -ms-grid-rows: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
  }
  .step-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }
  .step-count {
    display: none;
  }
  .archive-wrapper {
    margin-top: 40px;
  }
  .property-info-wrapper {
    padding-right: 18px;
    padding-left: 18px;
  }
  .name-icon-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .property-info-icon-wrapper {
    margin-right: 20px;
    margin-left: 0px;
  }
  .dual-cta-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .partner-logos-wrapper {
    margin-right: auto;
    margin-left: auto;
  }
  .purchase-option-toggle-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }
  .purchase-form {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }
  .property-price-box {
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .property-price {
    font-size: 23px;
    line-height: 25px;
  }
  .property-mortage-value {
    font-size: 14px;
  }
  .title-doc {
    font-size: 11px;
    line-height: 15px;
  }
  .site-button.header-action {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .hero-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    grid-template-areas: "hero-right" "hero-left";
    -ms-grid-columns: 2.5fr;
    grid-template-columns: 2.5fr;
    -ms-grid-rows: auto 20px auto;
    grid-template-rows: auto auto;
  }
  .nav-items {
    border-bottom: 1px solid hsla(0, 0%, 76.9%, 0.16);
    background-color: #fff;
  }
  .site-heading.primary {
    font-size: 50px;
    line-height: 48px;
  }
  .site-heading.sec {
    font-size: 30px;
    line-height: 30px;
  }
  .hero-right {
    width: 100%;
    padding-right: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .intro-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-column-gap: 0px;
    grid-template-areas: "intro-title" "company-highlights";
    -ms-grid-columns: 2.5fr;
    grid-template-columns: 2.5fr;
  }
  .company-highlight {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .highlight-icon {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .company-highlight-holder {
    margin-top: 32px;
  }
  .page-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .highlight-deets-wrapper {
    padding-left: 0px;
    border-left-style: none;
  }
  .intro-title-description {
    padding-right: 15px;
    padding-left: 15px;
  }
  .partner-logo {
    height: 35px;
    max-width: 100%;
  }
  .project-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .property-wrapper {
    margin-bottom: 0px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .advantage-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .step-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .step-icon {
    width: 150px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .step-heading {
    text-align: center;
  }
  .step-description {
    text-align: center;
  }
  .hero-image {
    width: 100%;
    clear: none;
    height: 300px;
    padding-top: 0px;
  }

  .archive-wrapper {
    margin-top: 40px;
    padding-right: 10px;
    padding-left: 10px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .sorting-bar {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    box-shadow: 0 30px 20px 11px rgba(0, 0, 0, 0.32);
  }
  .filter-form {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
  .name-icon-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .property-info-icon-wrapper {
    margin-right: 5px;
  }
  .property-info-icon-wrapper.amenities {
    margin-right: 15px;
  }
  .amenities-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .project-card-cta {
    padding-right: 65px;
    padding-left: 65px;
  }
  .purchase-option-toggle-wrapper {
    grid-column-gap: 0px;
  }
  .property-price-box {
    justify-items: start;
    grid-auto-flow: column;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
  .side-bar-btn {
    padding-right: 32px;
    padding-left: 32px;
  }
  .nav-menu-button {
    border-radius: 5px;
    background-color: transparent;
    color: #333;
  }
  .nav-menu-button.w--open {
    background-color: #136b3c;
    color: #fff;
  }
  .site-button {
    padding-right: 60px;
    padding-left: 60px;
  }
  .site-button.header-action {
    display: none;
  }
  .site-button.alt {
    padding-right: 37px;
    padding-left: 37px;
  }
  .site-heading-adv {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }
  .property-features-wrapper {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (max-width: 375px) {
  .hero-image {
    height: 250px;
    padding-top: 0px;
  }
}

@media screen and (max-width: 320px) {
  .hero-image {
    height: 200px;
    padding-top: 0px;
  }
}

.hero-left {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 3;
  -ms-grid-column-span: 3;
  -ms-grid-column-align: center;
  justify-self: center;
  grid-area: hero-left;
  -ms-grid-row-align: center;
  align-self: center;
}

.hero-right {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 7;
  -ms-grid-column-span: 5;
  -ms-grid-column-align: start;
  justify-self: start;
  grid-area: hero-right;
}

.intro-section {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.partner-logos-wrapper {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
  grid-area: partner-logo;
  -ms-grid-row-align: center;
  align-self: center;
}

.intro-title-description {
  -ms-grid-row: 3;
  -ms-grid-row-span: 3;
  -ms-grid-column: 7;
  -ms-grid-column-span: 3;
  grid-area: intro-title;
}

.company-highlight-image {
  -ms-grid-row: 3;
  -ms-grid-row-span: 5;
  -ms-grid-column: 3;
  -ms-grid-column-span: 3;
  grid-area: company-highlights;
  -ms-grid-row-align: end;
  align-self: end;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#project-sec-heading {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: our-projects;
}

.section-desc {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  grid-area: projects-deets;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

.property-wrapper {
  -ms-grid-row: 5;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
  grid-area: properties;
}

.site-heading-adv {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  grid-area: advantage-title;
}

.step-count {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
  -ms-grid-column-span: 7;
  grid-area: advantage-count;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

.steps {
  -ms-grid-row: 7;
  -ms-grid-column: 3;
  -ms-grid-column-span: 7;
  grid-area: steps;
}

@media screen and (max-width: 991px) {
  .site-heading-adv {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
  .step-count {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
  .steps {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
}

@media screen and (max-width: 767px) {
  .site-heading-adv {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .step-count {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .steps {
    -ms-grid-row: 3;
    -ms-grid-row-span: 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
}

.dual-cta-wrapper.advantage {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-area: dual-cta;
  -ms-grid-column-align: center;
  justify-self: center;
}

@media screen and (max-width: 991px) {
  .dual-cta-wrapper.advantage {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
}

.footer-widget.one {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: footer-widget-1;
  -ms-grid-column-align: start;
  justify-self: start;
}

.footer-widget.two {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
  grid-area: footer-widget-2;
  -ms-grid-column-align: start;
  justify-self: start;
}

.footer-widget.three {
  -ms-grid-row: 3;
  -ms-grid-column: 7;
  -ms-grid-column-align: start;
  justify-self: start;
  grid-area: footer-widget-3;
}

.footer-widget.four {
  -ms-grid-row: 3;
  -ms-grid-column: 9;
  -ms-grid-column-align: start;
  justify-self: start;
  grid-area: footer-widget-4;
}

.copyright {
  -ms-grid-row: 5;
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  grid-area: copyright;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

.logo-wrapper {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: start;
  justify-self: start;
}

.nav-menu-holder {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.nav-menu-button {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.site-button.header-action {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.property-info {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 5;
  grid-area: details-info-left;
}

#purchase-form-btn {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#installment-form-btn {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

.price-box-inspection-wrapper {
  -ms-grid-row: 1;
  -ms-grid-column: 9;
  grid-area: details-info-right;
}

@media screen and (max-width: 991px) {
  #hero-section.hero-section {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-column-align: auto;
    justify-self: auto;
  }
  .hero-left {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: center;
    align-self: center;
    grid-area: hero-left;
  }
  .hero-right {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 7;
    -ms-grid-column-span: 5;
    -ms-grid-column-align: center;
    justify-self: center;
    grid-area: hero-right;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  @media screen and (max-width: 991px) {
    .hero-left {
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
    }
    .hero-right {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
    }
    .hero-left {
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
    }
    .hero-right {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
    }
  }
  @media screen and (max-width: 479px) {
    .hero-left {
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }
    .hero-right {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }
    /* .hero-left {
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }
    .hero-right {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    } */
  }
  .partner-logos-wrapper {
    grid-area: partner-logos;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }
  .intro-title-description {
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    -ms-grid-column: 7;
    -ms-grid-column-span: 3;
    grid-area: intro-title;
  }
  .company-highlight-image {
    grid-area: company-highlight-image;
  }
  #project-sec-heading {
    grid-area: project-section-heading;
  }
  .section-desc {
    grid-area: project-section-deets;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .property-wrapper {
    grid-area: property-wrapper;
  }
  .nav-menu-button {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  .site-button.header-action {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  .sorting-bar {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }
  #filter-name-location {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  .filter-input-btn {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 5;
    grid-column-start: span 5;
    -ms-grid-column-span: 5;
    grid-column-end: span 5;
  }
  .price-box-inspection-wrapper {
    -ms-grid-row: 1;
    -ms-grid-column: 9;
    grid-area: details-info-right;
  }
  @media screen and (max-width: 991px) {
    .property-info {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }
    .price-box-inspection-wrapper {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }
    .price-box-inspection-wrapper {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }
  }
  .side-bar-btn {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
}

@media screen and (max-width: 991px) {
  #project-sec-heading {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .section-desc {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .property-wrapper {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
}

@media screen and (max-width: 767px) {
  .company-highlight-image {
    grid-area: company-highlight-image;
    -ms-grid-row-align: end;
    align-self: end;
  }
  #filter-name-location {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
  #filter-bedrooms {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #filter-bathrooms {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #filter-price {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
  #purchase-form-first-name {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #purchase-form-last-name {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  #purchase-form-phone {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #purchase-form-email {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  #purchase-form-btn {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }
  #install-form-first-name {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #install-form-last-name {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  #install-form-phone {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #install-form-email {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  #installment-form-btn {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }
}

@media screen and (max-width: 991px) {
  .intro-title-description {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .partner-logos-wrapper {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
  }
  .intro-title-description {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .company-highlight-image {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  .company-highlight-image {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
}

@media screen and (max-width: 767px) {
  .intro-title-description {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .partner-logos-wrapper {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
  .intro-title-description {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .company-highlight-image {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }
  .company-highlight-image {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }
}

@media screen and (max-width: 479px) {
  .intro-title-description {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .company-highlight-image {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .intro-title-description {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
}

@media screen and (max-width: 479px) {
  #filter-name-location {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }
  #filter-bedrooms {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #filter-bathrooms {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #filter-price {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  .filter-input-btn {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  .side-bar-btn {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
}

table caption {
  padding: 0.5em 0;
}

@media screen and (max-width: 767px) {
  table caption {
    border-bottom: 1px solid #ddd;
  }
}

/* Widget Placement */
#home-finance-widget {
  /* height: 100vh; */
}

#finance-plus-widget-affordability-eligibility {
  padding: 0 !important;
}

.finance-plus-widget-affordability-eligibility-wrapper {
  padding: 0 !important;
}

.finance-plus-widget-affordability-eligibility-wrapper h2 span {
  font-family: "DM Serif Display", sans-serif !important;
}

.finance-plus-widget-affordability-eligibility-form {
  background: #fff;
  margin-top: 0 !important;
}

.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0 2rem !important;
}

.mobile-menu-wrapper {
  z-index: 90;
  width: 100vw;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  background-color: rgb(19, 107, 61);
  -webkit-transition: background-color 500ms ease-out 1s;
  -moz-transition: background-color 500ms ease-out 1s;
  -o-transition: background-color 500ms ease-out 1s;
  transition: background-color 500ms ease-out 1s;
}

.mobile-menu-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid rgb(226, 226, 226, 0.3);
}

.mobile-menu-header-wrapper h4 {
  font-size: 1rem;
  color: #c5c2c2;
  font-family: "DM Serif Display", sans-serif;
  text-align: left;
  margin-top: 5px;
}

.mobile-menu-header_cancel_button svg {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(255, 255, 255, 0.7);
}

.mobile-menu-bottom-wrapper h4 {
  font-size: 1.35rem;
  color: #c5c2c2;
  font-family: "DM Serif Display", sans-serif;
  text-align: center;
  padding-top: 0.7rem;
}
.mobile-menu-bottom-list {
  background: transparent;
  list-style: outside none none;
  margin: 10px 0 0;
  font-family: "DM Serif Display", sans-serif;
  padding-top: 1.1rem;
}

.mobile-menu-bottom-list.last {
  padding-top: 0rem;
}

.mobile-menu-bottom-list li {
  list-style: none;
  text-transform: capitalize;
  margin: 1.3rem 0;
  text-align: center;
}

.mobile-menu-bottom-list li a {
  padding: 2px 0 3px;
  text-decoration: none;
  color: #ffffff;
  font-size: 1.25rem;
}

.mobile-menu-bottom-list li a:hover {
  color: #ffffff !important;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .col-md-6.affordability-eligibility-form {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .mobile-menu-wrapper {
    width: 60vw;
  }
  .mobile-menu-bottom-list {
    margin: 0;
    margin-left: -27px;
  }
  .mobile-menu-bottom-list li {
    text-align: left;
  }
  .mobile-menu-bottom-list li a {
    font-size: 1.15rem;
    padding: 1px 0 3px;
  }

  .mobile-menu-bottom-wrapper h4 {
    font-size: 1.15rem;
  }

  .about-us-page-mission-text,
  .about-us-page-vision-text {
    padding: 1rem;
  }
  .about-us-page-sub-header-text h2 {
    font-size: 35px;
  }

  #about-us-page {
    padding: 1rem 0;
  }
}
/* Slider */

.owl-carousel .owl-item {
  margin: 0px !important;
}

.owl-carousel .owl-item img {
  display: block;
  width: 70%;
}

.owl-carousel .slide img {
  height: 52px;
  /* width: 100%; */
}
.partner-logo-img.standard-chartered {
  height: 52px;
  background-image: url("../../resources/standard-chartered.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  filter: grayscale(100%);
  margin-top: 25px;
}

.partner-logo-img.stanbic-ibtc {
  height: 52px;
  background-image: url("../../resources/stanbic-ibtc.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
  margin-top: 25px;
}

.partner-logo-img.fmbn {
  height: 70px;
  background-image: url("../../resources/FMBN_opic_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
  margin-top: 10px;
}

.partner-logo-img.access-bank {
  height: 52px;
  background-image: url("../../resources/access-bank.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
  margin-top: 25px;
}

.partner-logo-img.fttrust {
  height: 52px;
  background-image: url("../../resources/first-trust-theme-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
  margin-top: 25px;
}

.partner-logo-img.fhf {
  height: 70px;
  background-image: url("../../resources/fhf-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
  margin-top: 10px;
}

.partner-logo-img.homebase {
  height: 60px;
  background-image: url("../../resources/homebase.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
  margin-top: 15px;
}

.partner-logo-img.nmrc {
  height: 60px;
  background-image: url("../../resources/nmrc.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
  margin-top: 15px;
}

/* Frequently Asked Questions */

#faqs-page {
  padding: 2rem 0;
  background: #f3f3f3;
}

.faqs-page-sub-header-text h2 {
  font-family: "DM Serif Display", sans-serif;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: left;
  color: #404040;
}

.opic-faqs-wrapper {
  padding: 2rem 0;
  background: #ffffff;
}

#opic-faqs {
  padding: 2rem 0;
  background: #ffffff;
}

.opic-faqs-wrapper h5 {
  margin: 2rem 0;
  font-family: "DM Serif Display", sans-serif;
  text-align: left;
  font-size: 1.4rem;
  color: #136b3d;
}

.opic-faqs-wrapper ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}
.opic-faqs-wrapper ul li {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 4px;
  /* padding-top: 18px; */
  border-top: 1px dotted #dce7eb;
}
.opic-faqs-wrapper ul li:nth-of-type(1) {
  animation-delay: 0.5s;
  border-top: none;
}
.opic-faqs-wrapper ul li:nth-of-type(2) {
  animation-delay: 0.75s;
}
.opic-faqs-wrapper ul li:nth-of-type(3) {
  animation-delay: 1s;
}
.opic-faqs-wrapper ul li:last-of-type {
  padding-bottom: 0;
}
.opic-faqs-wrapper ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 16px;
  right: 0;
}

.opic-faqs-wrapper ul li h2 {
  font-size: 1.2rem;
  font-weight: 600;
}

.opic-faqs-wrapper ul li p {
  font-size: 1rem;
  font-weight: 300;
  margin: 1rem 0;
  color: #696969;
  line-height: 20px;
  font-family: "DM Sans", sans-serif;
}

.opic-faqs-wrapper ul li i:before,
.opic-faqs-wrapper ul li i:after {
  content: "";
  position: absolute;
  background-color: #163e57;
  width: 3px;
  height: 9px;
}

.opic-faqs-wrapper ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}

.opic-faqs-wrapper ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}

.opic-faqs-wrapper ul li input[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.opic-faqs-wrapper ul li input[type="checkbox"]:checked ~ p {
  margin-top: 1rem;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}

.opic-faqs-wrapper ul li input[type="checkbox"]:checked ~ i:before {
  transform: translate(2px, 0) rotate(45deg);
}

.opic-faqs-wrapper ul li input[type="checkbox"]:checked ~ i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}

@media screen and (max-width: 991px) {
  #faqs-page {
    padding: 1rem 0;
    background: #f3f3f3;
  }
  .faqs-page-sub-header-text h2 {
    font-size: 1.7rem;
    line-height: 3rem;
  }
  #opic-faqs {
    padding: 0;
  }
}

@media screen and (max-width: 479px) {
  .faqs-page-sub-header-text h2 {
    font-size: 1.4rem;
  }
}

/* Contact Us Page */
#opic-contact-us {
  padding: 1rem 0 3rem;
  background: rgb(199, 250, 223, 0.385);
}

.opic-contact-us-wrapper {
  padding: 1rem 0;
}

.opic-contact-us-wrapper h2 {
  text-align: center;
  font-size: 100px;
  line-height: 85px;
  margin-bottom: 48px;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
}

.opic-contact-us-wrapper p {
  text-align: center;
}

.opic-contact-locations h5 {
  padding-bottom: 15px;
  font-size: 1.3rem;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
  margin-top: 3rem;
}

.opic-contact-locations h5::after {
  content: "";
  border-top: 2px solid rgb(19, 108, 62, 0.4);
  display: block;
  width: 25px;
  margin: 8px 0;
}

.opic-contact-locations p {
  font-size: 1rem;
  font-family: "DM Sans", sans-serif;
  color: #404040;
  text-align: left !important;
}

#opic-map-locations {
  padding: 2rem 0;
  background: #ffffff;
}

.opic-map-locations-wrapper {
  margin: 20px 0;
}

.opic-map-locations-wrapper h2 {
  font-size: 80px;
  line-height: 85px;
  margin-bottom: 48px;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
}

.opic-map-locations-wrapper p {
  font-size: 1rem;
  font-weight: 300;
  margin: 1rem 0;
  color: #696969;
  line-height: 20px;
  font-family: "DM Sans", sans-serif;
}

.opic-map-locations-wrapper .opic-map-location-list {
  display: flex;
  justify-content: space-between;
}

.opic-map-locations-wrapper .divider {
  width: 1.5px;
  height: 80px;
  background: rgb(0, 0, 0, 0.256);
}

/* .opic-map-address h5 {

} */

.opic-map-address address {
  font-size: 1rem;
  font-weight: 300;
  margin: 1rem 0;
  color: #696969;
  line-height: 20px;
  font-family: "DM Sans", sans-serif;
}

.opic-map-address a {
  color: #136b3d;
}

.opic-map-address a:hover {
  color: #136b3d;
  text-decoration: line-through;
}

.owl-carousel.owl-loaded {
  height: 100px;
}
.partner-logos-wrapper {
  padding: 0;
}

.owl-dots {
  display: none;
}

.site-button.o-button.mortgage-cta {
  padding: 10px 20px;
  margin: 0 auto !important;
  display: block;
}

.card-bed-bath-holder.no-border {
  border-top: none;
  padding: 11px 0;
}

/* Extras */

#our-projects {
  padding: 2rem 0 4rem;
  background: #fafafa;
  margin-top: -90px;
}

.our-projects-container {
  padding: 2rem 0;
}

.project-view-more {
  padding: 25px;
  background: #fff;
  border: 1px solid #ededed;
  /* height: 344px; */
  min-width: 300px;
  margin-right: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
  border-radius: 10px;
  background-color: #fff;
  cursor: default;
}

.project-view-more-img {
  width: 200px;
}

.project-view-more-img img {
  height: 100px;
}

.view-more-btn {
  padding-top: 28px;
  padding-bottom: 15px;
}

.view-more-btn a {
  background-color: transparent;
  border: 1px solid #121212;
  color: #121212 !important;
  padding: 10px 40px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}

.view-more-btn a:hover {
  background-color: #121212;
  color: #ffffff !important;
}

.project-view-more h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "DM Serif Display", sans-serif;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
}

.project-view-more p {
  font-family: "DM Sans", sans-serif;
  text-align: left;
}

.our-projects-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap; */
}

.our-projects-wrapper::-webkit-scrollbar {
  display: none;
}

.our-projects-wrapper .projects-list {
  display: inline-block;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  margin-right: 10px;
}

.projects-list {
  background: #fff;
  width: 592px;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border: 1px solid #e8e9ea;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2); */
}

.projects-list:hover {
  cursor: pointer;
  box-shadow: 0 4px 13px 0 hsla(0, 0%, 52.9%, 0.23);
}

#our-projects a {
  color: #333;
  text-decoration: none;
}

.projects-list-wrapper {
  position: relative;
}

.projects-img {
  height: 250px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.projects-list .projects-list-wrapper img {
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: auto;
}

.projects-type {
  position: absolute;
  bottom: 0px;
  left: 0;
  background-color: #136b3d;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
  padding: 0px 10px;
  font-family: "Droid Sans", sans-serif;
}

.project-border-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 590px;
  overflow: hidden;
  height: 100%;
  border: 1px solid transparent;
  border-bottom: none;
}

.projects-content {
  padding: 20px;
  font-size: 14px;
  border: none;
  height: 157px;
  box-sizing: border-box;
}

.project-logo {
  width: 71px;
  margin-right: 20px;
  padding: 5px 10px 0 0;
  position: absolute;
  top: 56.5%;
  left: 79%;
  /* float: left;
  width: 91px;
  margin-right: 20px;
  padding: 5px 10px 0 0;
  border-right: 1px solid #e5e5e5;
  margin-left: -10px; */
}

.project-logo img {
  height: 51px;
}

.project-info {
  float: left;
  width: 280px;
}

.project-info .project-name {
  font-size: 20px;
  color: #000;
  margin-bottom: 3px;
  font-family: "DM Sans", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 10px;
}

.project-info .project-location {
  font-size: 15px;
  color: #b1b1b1;
  margin-top: 10px;
  font-family: "DM Sans", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.project-price {
  float: right;
  text-align: right;
  /* width: 111px; */
  position: relative;
  height: 48px;
  margin-top: 10px;
}

.project-price p {
  margin: 0 0 4px;
  padding: 0px;
  font-family: "DM Sans", sans-serif;
  color: #136b3d;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.project-price .view-more-cta {
  display: inline-block;
  font-size: 14px;
  background: #136b3d;
  text-decoration: none;
  border-radius: 2px;
  padding: 5px 18px 5px 18px;
  position: absolute;
  color: #fff !important;
  left: 50px;
}

/* New & Events */
#news-events {
  padding: 2rem 0;
}

.news-events-header-text {
  padding: 1rem 0;
}

.news-events-header-text h2 {
  font-size: 45px;
  line-height: 45px;
  text-align: left;
  margin-bottom: 28px;
  font-family: "DM Serif Display", sans-serif;
  color: #404040;
  font-weight: 700;
}

.news-events-wrapper {
  padding: 0;
  border: none;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 30px;
}

.news-events-wrapper .news-event-photo {
  position: relative;
  overflow: hidden;
  display: block;
}

.news-events-wrapper .news-event-photo img {
  width: 100%;
}

.news-events-wrapper .date-box {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 13px;
  color: #fff;
  width: 55px;
  padding: 7px 0;
  text-align: center;
  border-radius: 3px;
  background: #136b3d;
  font-family: "DM Sans", sans-serif;
}

.news-events-wrapper .date-box span {
  text-transform: uppercase;
  font-size: 19px;
  display: block;
  font-weight: 700;
  line-height: 22px;
  color: #fff;
  font-family: "DM Serif Display", sans-serif;
}

.news-events-wrapper .new-events-wrapper-text {
  padding: 25px 20px 20px;
}

.news-events-wrapper .new-events-wrapper-text h4 {
  margin: 0 0 15px;
  font-size: 30px;
  font-weight: 700;
  font-family: "DM Serif Display", sans-serif;
}
.news-events-wrapper .new-events-wrapper-text h4 a {
  color: #136b3d !important;
}

.news-events-wrapper .new-events-wrapper-text p {
  font-size: 15.5px;
  margin-bottom: 25px;
  font-family: "DM Sans", sans-serif;
  color: #696969;
  line-height: 20px;
}

/* Project Card */
#opic-projects {
  background-color: #fafafa;
  padding: 2rem 0;
}

.container.ovh {
  overflow: hidden !important;
}

.opic-projects-list {
  margin: 20px 10px;
}

.projects-card {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  /* border-radius: 8px; */
  margin-bottom: 30px;
  overflow: hidden !important;
  position: relative;
  transition: all 0.3s ease;
}

.projects-card:hover {
  cursor: pointer;
  box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
}

.projects-card .projects-photo-wrapper {
  background-color: #1d293e;
  /* border-radius: 8px; */
  display: flex;
  overflow: hidden;
  margin: 10px 10px 0 10px;
  position: relative;
}

.projects-card .projects-photo-wrapper img {
  opacity: 0.4;
}

.img-whp {
  width: 100% !important;
}

.options-info {
  bottom: 0;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.project-status {
  border-radius: 3px;
  /* height: 25px; */
  line-height: 25px;
  text-align: left;
  width: 130px;
  padding: 0 20px;
  background: #136b3d;
}

.project-status p {
  font-size: 15.5px;
  font-family: "DM Sans", sans-serif;
  color: #ffffff;
  line-height: 20px;
  padding: 3px 0;
}

.projects-card .projects-photo-wrapper .options-info .price {
  bottom: 15px;
  font-size: 29px;
  color: #ffffff;
  left: 140px;
  line-height: 1.2;
  position: absolute;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
}

.projects-card .project-details {
  position: relative;
}

.projects-card .project-details .project-details-wrapper {
  padding: 20px;
}

.projects-card .project-details .project-details-wrapper h4 {
  font-size: 18px;
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}

.projects-card .project-details .project-details-wrapper p {
  font-size: 14px;
  color: #484848;
  line-height: 1.2;
}

.projects-card .project-footer {
  border-top: 1px solid #eeeeee;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.project-footer-logo img {
  height: 50px;
  width: 50px;
}
