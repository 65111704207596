.dashboard-contents {
  position: relative;
  top: 66px;
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .dashboard-contents {
    width: calc(100% - 260px) !important;
    left: 260px;
  }
}