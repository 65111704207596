/* General */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}

/* Container-Style */
.o-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.o-container:before,
.o-container:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.o-container:after {
  clear: both;
}

.o-container .o-row {
  margin-left: -10px;
  margin-right: -10px;
}

/* Screen Checks */
@media screen and (max-width: 991px) {
  .o-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .o-row,
  .o-container .o-row {
    margin-left: 0;
    margin-right: 0;
  }
  .o-nav-brand {
    padding-left: 10px;
  }
}

@media screen and (max-width: 479px) {
  .o-container {
    max-width: none;
  }
}

/* Nav Style */

.o-nav {
  position: relative;
  background: #dddddd;
  z-index: 1000;
}
.o-nav:before,
.o-nav:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.o-nav:after {
  clear: both;
}
.o-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}
.o-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.o-nav-link.o--current {
  color: #0082f3;
}
.o-nav-menu {
  position: relative;
  float: right;
}
[data-nav-menu-open] {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #c8c8c8;
  text-align: center;
  overflow: visible;
  min-width: 200px;
}
.o--nav-link-open {
  display: block;
  position: relative;
}
.o-nav-overlay {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}
.o-nav-overlay [data-nav-menu-open] {
  top: 0;
}
.o-nav[data-animation="over-left"] .o-nav-overlay {
  width: auto;
}
.o-nav[data-animation="over-left"] .o-nav-overlay,
.o-nav[data-animation="over-left"] [data-nav-menu-open] {
  right: auto;
  z-index: 1;
  top: 0;
}
.o-nav[data-animation="over-right"] .o-nav-overlay {
  width: auto;
}
.o-nav[data-animation="over-right"] .o-nav-overlay,
.o-nav[data-animation="over-right"] [data-nav-menu-open] {
  left: auto;
  z-index: 1;
  top: 0;
}
.o-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.o-nav-button.o--open {
  background-color: #c8c8c8;
  color: white;
}
.o-nav[data-collapse="all"] .o-nav-menu {
  display: none;
}
.o-nav[data-collapse="all"] .o-nav-button {
  display: block;
}
.o--nav-dropdown-open {
  display: block;
}
.o--nav-dropdown-toggle-open {
  display: block;
}
.o--nav-dropdown-list-open {
  position: static;
}

/* Screen Checks */
@media screen and (max-width: 991px) {
  .o-nav[data-collapse="medium"] .o-nav-menu {
    display: none;
  }
  .o-nav[data-collapse="medium"] .o-nav-button {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .o-nav[data-collapse="small"] .o-nav-menu {
    display: none;
  }
  .o-nav[data-collapse="small"] .o-nav-button {
    display: block;
  }
  .o-nav-brand {
    padding-left: 10px;
  }
}
@media screen and (max-width: 479px) {
  .o-nav[data-collapse="tiny"] .o-nav-menu {
    display: none;
  }
  .o-nav[data-collapse="tiny"] .o-nav-button {
    display: block;
  }
}

/* Button Style */
.o-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}
input.o-button {
  -webkit-appearance: button;
}

/* Form Style */
.o-form {
  margin: 0 0 15px;
}
.o-form-done {
  display: none;
  padding: 10px;
  text-align: center;
  background-color: #dddddd;
}
.o-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ed3232;
}

.o-form-fail.active {
  display: block !important;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.o-input,
.o-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.o-input:-moz-placeholder,
.o-select:-moz-placeholder {
  color: #999;
}
.o-input::-moz-placeholder,
.o-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.o-input:-ms-input-placeholder,
.o-select:-ms-input-placeholder {
  color: #999;
}
.o-input::-webkit-input-placeholder,
.o-select::-webkit-input-placeholder {
  color: #999;
}
.o-input:focus,
.o-select:focus {
  border-color: #3898ec;
  outline: 0;
}
.o-input[disabled],
.o-select[disabled],
.o-input[readonly],
.o-select[readonly],
fieldset[disabled] .o-input,
fieldset[disabled] .o-select {
  cursor: not-allowed;
  background-color: #eeeeee;
}
textarea.o-input,
textarea.o-select {
  height: auto;
}
.o-select {
  background-color: #f3f3f3;
}
.o-select[multiple] {
  height: auto;
}
.o-form-label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0px;
}
.o-radio {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}
.o-radio:before,
.o-radio:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.o-radio:after {
  clear: both;
}
.o-radio-input {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
  float: left;
  margin-left: -20px;
}
.o-radio-input {
  margin-top: 3px;
}

/* Slider-Style */
.o-slider {
  position: relative;
  height: 300px;
  text-align: center;
  background: #dddddd;
  clear: both;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
.o-slider-mask {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  left: 0;
  right: 0;
  height: 100%;
  white-space: nowrap;
}
.o-slide {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  white-space: normal;
  text-align: left;
}
.o-slider-nav {
  position: absolute;
  z-index: 2;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding-top: 10px;
  height: 40px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
.o-slider-nav.o-round > div {
  border-radius: 100%;
}
.o-slider-nav.o-num > div {
  width: auto;
  height: auto;
  padding: 0.2em 0.5em;
  font-size: inherit;
  line-height: inherit;
}
.o-slider-nav.o-shadow > div {
  box-shadow: 0 0 3px rgba(51, 51, 51, 0.4);
}
.o-slider-nav-invert {
  color: #fff;
}
.o-slider-nav-invert > div {
  background-color: rgba(34, 34, 34, 0.4);
}
.o-slider-nav-invert > div.o-active {
  background-color: #222;
}
.o-slider-dot {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  margin: 0 3px 0.5em;
  transition: background-color 100ms, color 100ms;
}
.o-slider-dot.o-active {
  background-color: #fff;
}
.o-slider-arrow-left,
.o-slider-arrow-right {
  position: absolute;
  width: 80px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  overflow: hidden;
  color: white;
  font-size: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.o-slider-arrow-left [class^="o-icon-"],
.o-slider-arrow-right [class^="o-icon-"],
.o-slider-arrow-left [class*=" o-icon-"],
.o-slider-arrow-right [class*=" o-icon-"] {
  position: absolute;
}
.o-slider-arrow-left {
  z-index: 3;
  right: auto;
}
.o-slider-arrow-right {
  z-index: 4;
  left: auto;
}
.o-icon-slider-left,
.o-icon-slider-right {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 1em;
  height: 1em;
}
