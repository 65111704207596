#lagos-state-app-authentication-wrapper.login {
  padding-top: 105px;
}

.lagos-state-app-authentication-form-wrapper
  .lagos-state-app-auth-register-option {
  padding: 20px 0 0px;
}

.lagos-state-app-auth-register-option {
  text-align: center;
  color: #3f4547;
  font-weight: 700;
  font-size: 17px;
}

.lagos-state-app-auth-register-option a {
  color: #009d47;
  margin-left: 5px;
}
