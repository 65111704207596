.user-details {
  border-bottom: 1px solid #c0c0c0;
}

.admin-profile-wrapper button {
  width: 100%;
  height: 45px;
}

@media screen and (min-width: 768px) {
  .user-details {
    border-right: 1px solid #c0c0c0;
    border-bottom: 0px;
  }
}