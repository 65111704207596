.property-slider-mask {
  position: relative;
}

.property-slider-mask .image-slides {
  overflow-x: auto;
  height: inherit;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.property-slider-mask .image-slides::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.property-slider-mask .image-slides {
  -ms-overflow-style: none;
}

.property-slider-mask .slider-controls {
  position: absolute;
  height: inherit;
  width: 100vw;
  display: flex;
  align-items: center;
  top: 0px;
}

.property-slider-mask .slider-control {
  height: inherit;
  margin: 0px;
}